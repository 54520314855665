import { buttonBorderRadius, buttonWidth, styledButtonWidth } from 'components/common/buttons/GroupedButton/constants';
import { AbsoluteWrapper } from 'componentsNewDesign/wrappers/grid/AbsoluteWrapper';
import { black } from 'constants/styles/colors';
import { disableDefaultButtonStyleMixin } from 'constants/styles/mixins';
import styled from 'styled-components';
import { Color, Sizes, Visibility } from 'types/styles';

export const ThreeDotsButton = styled.button<Pick<Sizes, 'width'>>`
    ${disableDefaultButtonStyleMixin};
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${styledButtonWidth};
`;

export const ItemStyledButton = styled.button<Color>`
    ${disableDefaultButtonStyleMixin};
    width: 100%;
    padding: 7px 10px;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    color: ${({ color }) => color || black};
    border-radius: 5px;
    transition: background-color 0.3s;

    :hover {
        background-color: #e7e7e7;
    }
`;

export const GroupedButtonsWrapper = styled(AbsoluteWrapper)<Visibility>`
    ${({ visible }) => !visible && 'visibility: hidden;'};
    padding: 16px;
    display: flex;
    flex-direction: column;
    width: ${buttonWidth};
    background-color: #f0f0f0;
    border-radius: ${buttonBorderRadius};
`;
