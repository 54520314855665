import { Footer } from 'componentsNewDesign/grid/Footer';
import { Pagination } from 'componentsNewDesign/layouts/Pagination';
import { defaultLimit } from 'constants/defaults/filterSettings';
import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { productVideosEvents, productVideosStores } from 'stores/videos/productVideos';
import { TotalRecords, WithoutFooter } from 'types/data';

const { updateValues } = productVideosEvents;

interface Props extends TotalRecords, WithoutFooter {}

export const ProductVideosFilterLayout: FC<Props> = ({ totalRecords, children, withoutFooter }) => {
    const { pageIndex, limit } = useStore(productVideosStores.values);

    const onCurrentPageChange = (page: number, pageSize: number | undefined) =>
        updateValues({
            pageIndex: page,
            limit: pageSize || defaultLimit
        });

    return (
        <>
            {/* <Section marginBottom="11px">
                <TotalBadge quantity={totalRecords && totalRecords !== -1 ? totalRecords : 0} />
            </Section> */}
            {children}
            {withoutFooter ? (
                <Pagination
                    currentIndex={pageIndex + 1}
                    defaultSize={limit}
                    totalItems={totalRecords}
                    onSizeChange={onCurrentPageChange}
                />
            ) : (
                <Footer>
                    <Pagination
                        currentIndex={pageIndex + 1}
                        defaultSize={limit}
                        totalItems={totalRecords}
                        onSizeChange={onCurrentPageChange}
                    />
                </Footer>
            )}
        </>
    );
};
