import womIcon from 'assets/icons/wom_icon.svg';
import history from 'browserHistory';
import { SimpleButton } from 'componentsNewDesign/common/buttons/SimpleButton';
import { CustomImg } from 'componentsNewDesign/common/imgComponents/CustomImg';
import { createWithdrawalLimitLink } from 'constants/routes';
import { black, white } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import { euroRate, totalCurrency } from 'pages/WithdrawalLimit/constants';
import {
    Currencies,
    DefaultLimitTitle,
    EuroSpan,
    LimitBlock,
    Title,
    Top,
    WomSpan,
    WomWrapper,
    Wrapper
} from 'pages/WithdrawalLimit/Header/styles';
import React from 'react';
import { withdrawalLimitsStores } from 'stores/withdrawalLimit';

const { withdrawalLimitsQuery } = withdrawalLimitsStores;

export const Header = () => {
    const { items } = useStore(withdrawalLimitsQuery);
    const defaultLimit = items
        ? items.reduce((total, currentItem) => total + Number(currentItem.maxLimitInWom || 0), 0)
        : 0;
    const defaultLimitInEuro = totalCurrency(defaultLimit, euroRate);

    const onCreateLimit = () => {
        history.push(createWithdrawalLimitLink);
    };

    return (
        <Wrapper>
            <Top>
                <Title>Withdrawal Limit</Title>
                <SimpleButton background={black} color={white} onClick={onCreateLimit}>
                    Create Limit
                </SimpleButton>
            </Top>
            <LimitBlock>
                <DefaultLimitTitle>Default Limit</DefaultLimitTitle>
                <Currencies>
                    <WomWrapper>
                        <WomSpan>{defaultLimit}</WomSpan>
                        <CustomImg height="12px" src={womIcon} width="10px" />
                    </WomWrapper>
                    <EuroSpan>{`${defaultLimitInEuro} Euros`}</EuroSpan>
                </Currencies>
            </LimitBlock>
        </Wrapper>
    );
};
