import { grey13, grey27, grey29, white } from 'constants/styles/colors';
import { ellipsisMixin, flexCenter } from 'constants/styles/mixins';
import { sm, xl } from 'constants/styles/sizes';
import { tableDataPadding } from 'pages/Users/User/UserReferralsTable/constants';
import styled from 'styled-components';
import { Sizes } from 'types/styles';

interface TableProps extends Sizes {
    justifyContent?: string;
}

export const Wrapper = styled.div`
    width: 100%;

    @media (max-width: ${xl}) {
        overflow: auto;
    }
`;

export const EllipsisTableText = styled.a`
    margin-left: 8px;
    font-size: 13px;
    line-height: 15px;
    color: ${white};
    max-width: 340px;
    ${ellipsisMixin};
    transition: text-decoration 0.3s linear;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
        color: ${white};
    }

    @media (max-width: ${xl}) {
        max-width: 250px;
    }

    @media (max-width: ${sm}) {
        max-width: 200px;
    }
`;

export const Text = styled.span`
    font-size: 13px;
    line-height: 15px;
    color: ${white};
`;

export const TableBody = styled.tbody`
    width: 100%;
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px;
    background-color: ${grey29};
`;
export const TableRow = styled.tr`
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
    ${flexCenter};
`;

export const TableTitle = styled.th<TableProps>`
    color: ${grey13};
    background-color: ${grey27};
    height: 38px;
    width: ${({ width }) => width || 'fit-content'};
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    padding-left: 32px;
    padding-right: 32px;
    justify-content: ${({ justifyContent }) => justifyContent || 'center'};

    &:first-child {
        border-radius: 8px 0 0 0;
    }

    &:last-child {
        border-radius: 0 8px 0 0;
    }

    @media (max-width: ${sm}) {
        width: 300px;
    }
`;

export const Cell = styled.td<TableProps>`
    height: 54px;
    width: ${({ width }) => width || 'fit-content'};
    padding: ${tableDataPadding};
    display: flex;
    align-items: center;
    padding-left: 32px;
    padding-right: 32px;
    justify-content: ${({ justifyContent }) => justifyContent || 'center'};
`;
