import { headerHeight } from 'componentsNewDesign/grid/Header/constants';
import { grey23 } from 'constants/styles/colors';
import { fullHeaderHeight, sm } from 'constants/styles/sizes';
import styled from 'styled-components';

export const PromotionCardWrapper = styled.div`
    background: ${grey23};
    min-height: calc(100vh - ${fullHeaderHeight} - 125px);
    width: 100%;
    padding: 20px 25px;
    border-radius: 25px;

    @media (min-width: 481px) {
        margin-top: ${headerHeight};
    }

    @media (max-width: ${sm}) {
        padding: 16px 18px;
    }
`;
