export const getCategoriesList = (items: BULLZ.CategorySingleLanguageResponse[] | null | undefined) => {
    if (items) {
        const categoriesList = items
            .map(({ tagId, emojiCode }) => (emojiCode && tagId ? emojiCode + tagId : ''))
            .filter(tagId => !!tagId);

        return ['All', ...categoriesList];
    }
    return [];
};

export const getCategoriesValues = (items: BULLZ.CategorySingleLanguageResponse[] | null | undefined) => {
    if (items) {
        const categoriesList = items.map(({ tagId }) => (tagId ? tagId : '')).filter(tagId => !!tagId);

        return [undefined, ...categoriesList];
    }
    return [];
};
