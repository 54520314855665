import { Loader } from 'components/common/dynamic/Loader';
import { TrendingUserCard } from 'componentsNewDesign/layouts/cards/TrendingUserCard';
import { TrendingContainer } from 'componentsNewDesign/layouts/containers/TrendingContainer';
import { Pagination } from 'componentsNewDesign/layouts/Pagination';
import { Section } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { useStore } from 'effector-react';
import { TrendingButton } from 'pages/Trendings';
import React, { useEffect } from 'react';
import { agentEffects, agentEvents, agentStores, defaultMembersParams } from 'stores/agent';
import { addInfluencerToAgentModal } from 'stores/initialize/initialize.modal.store';

export const AgentInfluencers = () => {
    const loading = useStore(agentEffects.getAgentMembersFx.pending);
    const currentUserAgentInfo = useStore(agentStores.$currentUserAgentInfo);
    const agentMembers = useStore(agentStores.$agentMembers);
    const agentMembersParams = useStore(agentStores.$agentMembersParams);

    const currentIndex =
        agentMembersParams.params?.pageIndex !== undefined ? agentMembersParams.params.pageIndex + 1 : 0;
    const defaultSize = agentMembersParams.params.limit || 0;

    useEffect(() => {
        if (currentUserAgentInfo.id) {
            agentEvents.setAgentMembersParams({
                agentId: currentUserAgentInfo.id
            });
        }
    }, [currentUserAgentInfo]);

    const onAgentInfluencersPageChange = (pageIndex: number, limit: number | undefined) => {
        if (currentUserAgentInfo.id) {
            agentEvents.setAgentMembersParams({
                params: {
                    pageIndex,
                    limit: limit || defaultMembersParams.limit
                }
            });
        }
    };

    const removeMemberFromAgent = (userId?: string) => {
        if (currentUserAgentInfo.id && userId) {
            agentEffects.removeMemberFromAgentFx({
                agentId: currentUserAgentInfo.id,
                userId
            });
        }
    };

    return (
        <TrendingContainer
            features={
                <TrendingButton
                    onClick={() =>
                        addInfluencerToAgentModal.openModal({
                            agentId: currentUserAgentInfo.id || ''
                        })
                    }
                >
                    Add Influencer
                </TrendingButton>
            }
            title="Agent’s Influencers"
            totalRecords={currentUserAgentInfo.membersCount || 0}
        >
            {loading ? (
                <Section justifyCenter>
                    <Loader size="large" />
                </Section>
            ) : (
                agentMembers.items &&
                agentMembers.items?.length > 0 && (
                    <>
                        <Section marginBottom="34px">
                            {agentMembers.items?.map(member => (
                                <TrendingUserCard
                                    key={member.userId}
                                    {...member}
                                    onRemove={() => removeMemberFromAgent(member.userId)}
                                />
                            ))}
                        </Section>
                        <Pagination
                            currentIndex={currentIndex}
                            defaultSize={defaultSize}
                            totalItems={currentUserAgentInfo.membersCount || 0}
                            onSizeChange={onAgentInfluencersPageChange}
                        />
                    </>
                )
            )}
        </TrendingContainer>
    );
};
