import { SimpleButton } from 'componentsNewDesign/common/buttons/SimpleButton';
import { Column } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { black, black5, grey27, grey28, white } from 'constants/styles/colors';
import { flexCenter } from 'constants/styles/mixins';
import { primaryBorderRadius, sm } from 'constants/styles/sizes';
import styled from 'styled-components';
import { Visibility } from 'types/styles';

export const Wrapper = styled.div<Visibility>`
    position: fixed;
    top: 0;
    left: 0;
    ${flexCenter};
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.612407);
    padding: 0 63px;
    padding-bottom: 10px;
    display: ${({ visible }) => (visible ? 'flex' : 'none')};
    z-index: 100;
    overflow: auto;

    @media (max-width: ${sm}) {
        padding: 0 10px;
    }
`;

export const Modal = styled(Column)`
    position: relative;
    align-items: center;
    width: 420px;
    min-height: 440px;
    border-radius: 16px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.0778245);
    background: ${grey28};
    margin: auto;
    z-index: 10;

    @media (max-width: ${sm}) {
        width: 256px;
        min-height: 300px;
        border-radius: ${primaryBorderRadius};
    }
`;

export const Triangle = styled.div`
    position: relative;
    top: 1px;
    margin-top: 200px;
    width: 0px;
    height: 0px;
    border-left: 200px solid transparent;
    border-right: 200px solid transparent;
    border-bottom: 50px solid white;

    @media (max-width: ${sm}) {
        border-left: 125px solid transparent;
        border-right: 125px solid transparent;
        margin-top: 86px;
    }
`;

export const ModalContentWrapper = styled(Column)`
    width: inherit;
    height: 200px;
    padding: 0px 20px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    background-color: ${white};
    border-radius: 16px;

    @media (max-width: ${sm}) {
        height: 164px;
        border-radius: ${primaryBorderRadius};
    }
`;

export const TextWrapper = styled(Column)`
    align-items: center;
    width: 400px;
    margin-top: 17px;
    margin-bottom: 28px;

    @media (max-width: ${sm}) {
        width: 200px;
        margin-top: 10px;
        margin-bottom: 20px;
    }
`;

export const Title = styled.span`
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    color: ${black};

    @media (max-width: ${sm}) {
        font-size: 24px;
        line-height: 28px;
    }
`;

export const StyledSpan = styled.span`
    text-align: center;
    font-size: 17px;
    line-height: normal;
    color: ${black5};

    @media (max-width: ${sm}) {
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
    }
`;

export const StyledButton = styled(SimpleButton)`
    width: 100px;
    padding: 8px;
    margin-bottom: 40px;
    font-size: 16px;
    font-weight: 400;
    color: ${white};
    border-radius: 4px;
    background-color: ${black};

    :hover {
        color: ${white};
        background-color: ${grey27};
    }

    @media (max-width: ${sm}) {
        width: 90px;
        font-size: 11px;
        line-height: 13px;
    }
`;
