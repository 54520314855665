import { agentRole } from 'constants/defaults/users';
import { forward, guard, sample } from 'effector';
import { assignAgentModal } from 'stores/initialize/initialize.modal.store';
import { usersEvents, usersStores } from './../users/users';
import { agentEffects, agentEvents, agentStores } from './index';

sample({
    clock: agentEffects.addAgentRoleFx.done,
    source: usersStores.user,
    fn: user => ({ ...user, roles: [...(user?.roles || []), agentRole] }),
    target: usersEvents.setUser
});

sample({
    clock: agentEffects.deleteAgentRoleFx.done,
    source: usersStores.user,
    fn: user => ({ ...user, roles: user.roles?.filter(role => role !== agentRole) }),
    target: usersEvents.setUser
});

forward({
    from: agentEffects.addAgentRoleFx.done,
    to: agentEffects.addAgentRoleDoneFx
});

forward({
    from: agentEffects.deleteAgentRoleFx.done,
    to: agentEffects.deleteAgentRoleDoneFx
});

sample({
    clock: agentEffects.getPotentialAgentInfoFx.done,
    target: agentEffects.addMemberToAgentFx,
    fn: clock => ({ data: { userId: clock.result.userId }, agentId: clock.result?.data?.id || '' })
});

sample({
    clock: agentEffects.addMemberToAgentFx.done,
    target: agentEffects.getMemberInfoAboutAgentFx,
    fn: clock => clock.params.data.userId || ''
});

guard({
    clock: agentEffects.changeMemberAgentFx.doneData,
    filter: (userId): userId is string => !!userId,
    target: agentEffects.getMemberInfoAboutAgentFx
});

sample({
    clock: agentEffects.getMemberInfoAboutAgentFx.done,
    target: agentEffects.getMemberAgentInfoFx,
    fn: clock => clock.result.data?.agentInfo?.userId || ''
});

forward({
    from: agentEffects.addMemberToAgentFx.done,
    to: agentEffects.addMemberToAgentDoneFx
});

forward({
    from: agentEffects.addMemberToAgentFx.done,
    to: assignAgentModal.closeModal
});

forward({
    from: agentEffects.removeMemberFromAgentFx.done,
    to: agentEffects.removeMemberFromAgentDoneFx
});

sample({
    clock: agentEffects.removeMemberFromAgentFx.done,
    target: agentEffects.getMemberInfoAboutAgentFx,
    fn: clock => clock.params.userId || ''
});

sample({
    source: agentStores.$currentUserAgentInfo,
    clock: agentEffects.removeMemberFromAgentFx.done,
    target: agentEffects.getUserAgentInfoFx,
    fn: source => source.userId || ''
});

sample({
    source: agentStores.$agentMembersParams,
    clock: agentEvents.setAgentMembersParams,
    target: agentEffects.getAgentMembersFx,
    fn: (source, clock) => ({ ...source, ...clock })
});

sample({
    source: agentStores.$currentUserAgentInfo,
    clock: agentEffects.addMemberToAgentFx.done,
    target: agentEffects.getUserAgentInfoFx,
    fn: source => source.userId || ''
});

forward({
    from: agentEffects.updateAgentInfoFx.done,
    to: agentEffects.updateAgentInfoDoneFx
});

sample({
    clock: agentEffects.updateAgentInfoFx.done,
    target: agentEvents.setCurrentUserAgentInfo,
    fn: clock => clock.result
});
