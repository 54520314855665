import axios from './axios';

export const getWithdrawalLimits = (data: BULLZ.WithdrawalLimitQueryRequest) =>
    axios<BULLZ.WithdrawalLimitQueryResponse>({
        url: '/withdrawal-limit/query',
        data
    });

export const getSingleWithdrawalLimit = (data: BULLZ.WithdrawalLimitGetRequest) =>
    axios<BULLZ.WithdrawalLimitResponse>({ url: '/withdrawal-limit/get', data });

export const createWithdrawalLimit = (data: BULLZ.WithdrawalLimitCreateRequest) =>
    axios<BULLZ.WithdrawalLimitResponse>({ url: '/withdrawal-limit/create', data });

export const deleteWithdrawalLimit = (data: BULLZ.WithdrawalLimitDeleteRequest) =>
    axios<BULLZ.MessageResponseBase>({ url: '/withdrawal-limit/delete', data });

export const updateWithdrawalLimit = (data: BULLZ.WithdrawalLimitUpdateRequest) =>
    axios<BULLZ.WithdrawalLimitResponse>({ url: '/withdrawal-limit/update', data });
