import { Column, Row } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { white, grey29 } from 'constants/styles/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
    margin-bottom: 8px;
`;

export const Top = styled(Row)`
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`;

export const Title = styled.h2`
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: ${white};
`;

export const LimitBlock = styled(Column)`
    background: ${grey29};
    border-radius: 8px;
    padding: 12px 28px;
`;

export const DefaultLimitTitle = styled.h3`
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    color: rgba(255, 255, 255, 0.4);
    margin-bottom: 8px;
`;

export const Currencies = styled(Row)`
    align-items: center;
`;

export const WomWrapper = styled(Row)`
    align-items: center;
    margin-right: 8px;
`;

export const WomSpan = styled.span`
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    margin-right: 3px;
`;

export const EuroSpan = styled.span`
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    color: rgba(255, 255, 255, 0.4);
`;
