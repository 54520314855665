import { useMediaQuery } from '@material-ui/core';
import history from 'browserHistory';
import { TotalBadge } from 'componentsNewDesign/common/badges/TotalBadge';
import { SimpleButton } from 'componentsNewDesign/common/buttons/SimpleButton';
import { PromotionsTable } from 'componentsNewDesign/common/tables/PromotionsTable';
import { Span } from 'componentsNewDesign/common/typography/Span';
import { Loader } from 'componentsNewDesign/dynamic/Loader';
import { MarketingToolsFilterLayout } from 'componentsNewDesign/layouts/filterLayouts/MarketingToolsFilterLayout';
import { MainLayout } from 'componentsNewDesign/layouts/MainLayout';
import { Empty } from 'componentsNewDesign/layouts/resultLayouts/Empty';
import { Section } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { promotionCreateLink } from 'constants/routes';
import { black, grey32, white } from 'constants/styles/colors';
import { sm } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import { LayoutContentWrapper, TableWrapper } from 'pages/MarketingTools/styles';
import React, { useEffect } from 'react';
import { promotionsEvents, promotionsStores } from 'stores/promotions/promotions';

const { invokeGetItems, setIsFirstToFalse } = promotionsEvents;
const { isFirst } = promotionsStores;

export const MarketingTools = () => {
    const { items, totalRecords } = useStore(promotionsStores.promotions);
    const isPageLoading = useStore(promotionsStores.loading);
    const onCreateButtonClick = () => history.push(promotionCreateLink);
    const isMobile = useMediaQuery(`(max-width: ${sm})`);

    useEffect(() => {
        if (isFirst) {
            invokeGetItems();
            setIsFirstToFalse();
        }
    }, []);

    return (
        <MainLayout>
            <MarketingToolsFilterLayout totalRecords={totalRecords}>
                <LayoutContentWrapper>
                    <Section alignCenter justifyBetween marginBottom="10px">
                        <TotalBadge quantity={totalRecords} />
                        {isMobile && (
                            <SimpleButton
                                background={white}
                                backgroundHover={grey32}
                                borderRadius="4px"
                                color={black}
                                fontSize="10px"
                                fontWeight="400"
                                height="30px"
                                textHover={white}
                                width="95px"
                                onClick={onCreateButtonClick}
                            >
                                Create Promotion
                            </SimpleButton>
                        )}
                    </Section>
                    <TableWrapper>
                        <Section alignCenter justifyBetween marginBottom={isMobile ? '14px' : '26px'}>
                            <Span fontSize="16px" fontWeight="700" lineHeight="18px">
                                Current Promotions
                            </Span>
                            {!isMobile && (
                                <SimpleButton
                                    background={white}
                                    backgroundHover={grey32}
                                    borderRadius="4px"
                                    color={black}
                                    fontSize="10px"
                                    fontWeight="400"
                                    height="30px"
                                    textHover={white}
                                    width="95px"
                                    onClick={onCreateButtonClick}
                                >
                                    Create Promotion
                                </SimpleButton>
                            )}
                        </Section>

                        {isPageLoading ? (
                            <Section justifyCenter>
                                <Loader size="large" />
                            </Section>
                        ) : (
                            <Section>
                                {!!items?.length ? (
                                    <PromotionsTable items={items} />
                                ) : (
                                    <Empty
                                        emptyLayoutMarginTop="100px"
                                        //title={defaultMessage}
                                        titleFontSize="16px"
                                        titleFontWeight="500"
                                        titleLineHeight="26px"
                                        titleWidth="390px"
                                    />
                                )}
                            </Section>
                        )}
                    </TableWrapper>
                </LayoutContentWrapper>
            </MarketingToolsFilterLayout>
        </MainLayout>
    );
};
