import { headerHeight } from 'componentsNewDesign/grid/Header/constants';
import { ContentWrapper } from 'componentsNewDesign/wrappers/ContentWrapper';
import { grey30 } from 'constants/styles/colors';
import { fullHeaderHeight, lg, xs } from 'constants/styles/sizes';
import styled from 'styled-components';

export const LayoutContentWrapper = styled.div`
    min-height: calc(100vh - ${fullHeaderHeight} - 125px);
    width: 100%;
    padding: 8px 16px;
    border-radius: 25px;
    background: ${grey30};

    @media (min-width: ${xs}) {
        margin-top: ${headerHeight};
    }

    @media (min-width: ${lg}) {
        padding: 10px 25px;
    }
`;

export const TableWrapper = styled(ContentWrapper)`
    padding: 16px 12px;
    border-radius: 8px 8px 0px 0px;

    @media (min-width: ${lg}) {
        padding-left: 23px;
        padding-right: 23px;
    }
`;
