import { externalNetworkId, NetworkType } from 'constants/defaults/networks';

export const socialNetworkList = Object.keys(externalNetworkId) as NetworkType[];
export const defaultMin = 0;
export const defaultMax = 10000000;

export const power = {
    b: 9,
    m: 6,
    k: 3
};
