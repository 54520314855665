import { createEffect, createStore } from 'effector';
import { API } from 'services';

//Effects
export const getUserReferralLinksFx = createEffect({
    handler: async (id: string) => await API.adminUsers.getUserReferralLinks(id)
});

//Stores
const $userReferralLinks = createStore<BULLZ.GetReferralsDetailedResponseApiResponse>({}).on(
    getUserReferralLinksFx.doneData,
    (_, newState) => newState
);

//Exports
export const referralEffects = { getUserReferralLinksFx };
export const referralStores = { $userReferralLinks };
