import { useMediaQuery } from '@material-ui/core';
import history from 'browserHistory';
import { CustomImg } from 'componentsNewDesign/common/imgComponents/CustomImg';
import { TableData, TableProps } from 'componentsNewDesign/common/tables/AffiliateLinksTable/types';
import { ContentWrapper } from 'componentsNewDesign/wrappers/ContentWrapper';
import { Row } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { usersLink } from 'constants/routes';
import { grey29 } from 'constants/styles/colors';
import { sm } from 'constants/styles/sizes';
import {
    cellsHeaderPosition,
    tableTitles,
    widthArray,
    widthArrayMobile
} from 'pages/Users/User/UserReferralsTable/constants';
import {
    Cell,
    EllipsisTableText,
    Table,
    TableBody,
    TableRow,
    TableTitle,
    Text,
    Wrapper
} from 'pages/Users/User/UserReferralsTable/styles';
import { getFormattedDate, getFormattedTime } from 'pages/Users/User/UserReferralsTable/utils';
import React from 'react';

interface Props extends BULLZ.GetReferralsDetailedResponseApiResponse {
    empty?: boolean;
}

export const UserReferralTable = ({ data }: Props) => {
    const handleClickAvatar = (id: string) => () => {
        history.replace(usersLink + '/' + id);
    };

    const isMobile = useMediaQuery(`(max-width: ${sm})`);

    const tableData: TableData[] =
        data && data.items?.length
            ? data.items.map(({ username, userId, utcSignedUp, utcDownloaded, profileImageUrl }) => ({
                  cells: [
                      <Row key={userId} alignCenter noWrap>
                          {userId && profileImageUrl && (
                              <CustomImg
                                  center
                                  objectFit
                                  pointer
                                  alt="Avatar"
                                  borderRadius="50%"
                                  height="34px"
                                  src={profileImageUrl}
                                  width="34px"
                                  onClick={handleClickAvatar(userId)}
                              />
                          )}

                          <EllipsisTableText href={usersLink + '/' + userId}>{username || '-'}</EllipsisTableText>
                      </Row>,

                      <Row key={userId}>
                          <Text>
                              {utcDownloaded
                                  ? `${getFormattedDate(utcDownloaded)} ${getFormattedTime(utcDownloaded)} (UTC)`
                                  : '-'}
                          </Text>
                      </Row>,

                      <Row key={userId}>
                          <Text>
                              {utcSignedUp
                                  ? `${getFormattedDate(utcSignedUp)} ${getFormattedTime(utcSignedUp)} (UTC)`
                                  : '-'}
                          </Text>
                      </Row>
                  ]
              }))
            : [];

    return (
        <TableConstructor
            cellsWidth={isMobile ? widthArrayMobile : widthArray}
            data={tableData}
            tableTitles={tableTitles}
            tableWidth="100%"
        />
    );
};

const TableConstructor = ({ tableTitles, data, cellsWidth, tableWidth }: TableProps) => (
    <Wrapper>
        <ContentWrapper backgroundColor={grey29} borderRadius="0px" width={tableWidth}>
            <Table>
                <TableRow>
                    {tableTitles.map((title, index) => (
                        <TableTitle
                            key={title}
                            height="35px"
                            justifyContent={cellsHeaderPosition ? cellsHeaderPosition[index] : 'center'}
                            width={cellsWidth[index]}
                        >
                            {title}
                        </TableTitle>
                    ))}
                </TableRow>
            </Table>

            <Row height="fit-content">
                <Table>
                    <TableBody>
                        {data.map(({ cells }, i) => (
                            <TableRow key={i.toString()}>
                                {cells.map((cell, i) => (
                                    <Cell
                                        key={i.toString()}
                                        justifyContent={cellsHeaderPosition ? cellsHeaderPosition[i] : 'center'}
                                        width={cellsWidth[i]}
                                    >
                                        {cell}
                                    </Cell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Row>
        </ContentWrapper>
    </Wrapper>
);
