import { defaultCategoriesValues } from 'constants/defaults/categories';
import { asyncError } from 'constants/notifications';
import { createEffect, createStore } from 'effector';
import { API } from 'services';
import { message } from 'stores/alerts';

const getCategories = createEffect({
    handler: async ({
        pageIndex,
        limit,
        language = defaultCategoriesValues.language
    }: Paths.Categories.Get.QueryParameters) => {
        try {
            const { isSuccess, data, error } = await API.categories.getCategoriesByLanguage({
                pageIndex,
                limit,
                language
            });

            if (!isSuccess) {
                error?.description && message.error(error?.description);
            }

            return data;
        } catch (error) {
            const errorMessage = error.data.message;
            message.error(errorMessage || asyncError);
        }
    }
});

const categories = createStore<BULLZ.GetCategoriesResponse>({}).on(getCategories.doneData, (_, newState) => newState);

export const categoriesEffects = { getCategories };
export const categoriesStores = { categories };
