import womIcon from 'assets/icons/wom_icon.svg';
import browserHistory from 'browserHistory';
import { CopyButton } from 'componentsNewDesign/common/buttons/CopyButton';
import { TrashIcon } from 'componentsNewDesign/common/icons/TrashIcon';
import { CustomImg } from 'componentsNewDesign/common/imgComponents/CustomImg';
import { Empty } from 'componentsNewDesign/layouts/resultLayouts/Empty';
import { Row } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { MarginWrapper } from 'componentsNewDesign/wrappers/grid/MarginWrapper';
import { withdrawalLimitLink } from 'constants/routes';
import { useStore } from 'effector-react';
import { euroRate, totalCurrency } from 'pages/WithdrawalLimit/constants';
import { getColumnWidth, limitColumns } from 'pages/WithdrawalLimit/LimitTable/constants';
import React, { MouseEvent } from 'react';
import { deleteLimitModal } from 'stores/initialize/initialize.modal.store';
import { locationStores } from 'stores/location/location';
import { defaultWithdrawalLimits, withdrawalLimitsEffects, withdrawalLimitsStores } from 'stores/withdrawalLimit';
import {
    CountryWrapper,
    DeleteButton,
    DeleteWrapper,
    LimitWrapper,
    Table,
    TableData,
    TableHead,
    TableHeader,
    TableRow
} from './styles';

interface DataTable {
    [key: string]: any;
}

export const LimitTable = () => {
    const { items } = useStore(withdrawalLimitsStores.withdrawalLimitsQuery);
    const { countries } = useStore(locationStores.countriesList);

    const deleteLimit = async (id: string | undefined) => {
        if (id) {
            deleteLimitModal.openModal({
                okButtonText: 'OK',
                cancelButtonText: 'Cancel',
                title: 'Are you sure you want to delete limit?',
                limitId: id,
                onOk: async () => {
                    await withdrawalLimitsEffects.deleteWithdrawalLimit({ id });
                    withdrawalLimitsEffects.getWithdrawalLimitQuery(defaultWithdrawalLimits);
                }
            });
        }
    };

    const data = items?.map(({ id, maxLimitInWom, regionId }) => {
        const location = countries ? countries.find(({ countryCode }) => countryCode === regionId)?.countryName : '';
        const dataTable: DataTable = {
            id,
            displayId: id ? (
                <Row alignCenter noWrap>
                    <MarginWrapper marginRight="4px">{id}</MarginWrapper>
                    <CopyButton
                        subject={id}
                        success="Limit ID was copied"
                        onClick={(e: MouseEvent<Element>) => {
                            e.stopPropagation();
                        }}
                    />
                </Row>
            ) : null,
            limit: maxLimitInWom ? (
                <LimitWrapper>
                    {maxLimitInWom}
                    <CustomImg height="12px" src={womIcon} width="10px" />
                </LimitWrapper>
            ) : null,
            euro: maxLimitInWom ? <div>{totalCurrency(maxLimitInWom, euroRate)}</div> : null,
            location: <CountryWrapper>{location}</CountryWrapper>,
            delete: (
                <DeleteWrapper>
                    <DeleteButton
                        onClick={(e: any) => {
                            e.stopPropagation();
                            deleteLimit(id);
                        }}
                    >
                        <TrashIcon />
                    </DeleteButton>
                </DeleteWrapper>
            )
        };
        return dataTable;
    });

    const onRowClick = (id: number) => () => browserHistory.push(`${withdrawalLimitLink}/${id}`);

    if (!data || data.length <= 0) {
        return <Empty title="There is no limits" />;
    }

    return (
        <Table>
            <TableHead>
                <tr>
                    {limitColumns.map(({ title, width }) => (
                        <TableHeader key={title} width={getColumnWidth(width)}>
                            {title}
                        </TableHeader>
                    ))}
                </tr>
            </TableHead>
            <tbody>
                {data.map(dataRow => (
                    <TableRow key={dataRow.id} onClick={onRowClick(dataRow.id)}>
                        {limitColumns.map(({ key, width }, i) => (
                            <TableData key={i.toString()} width={getColumnWidth(width)}>
                                <Row alignCenter justifyCenter width="100%">
                                    {dataRow[key]}
                                </Row>
                            </TableData>
                        ))}
                    </TableRow>
                ))}
            </tbody>
        </Table>
    );
};
