import backArrowImg from 'assets/back_arrow.svg';
import closeButton from 'assets/black_close_button.svg';
import history from 'browserHistory';
import { Loader } from 'components/common/dynamic/Loader';
import { Section } from 'components/grid/Section';
import { CustomImg } from 'componentsNewDesign/common/imgComponents/CustomImg';
import { SearchSelect } from 'componentsNewDesign/common/inputs/SearchSelect';
import { Span } from 'componentsNewDesign/common/typography/Span';
import { MainLayout } from 'componentsNewDesign/layouts/MainLayout';
import { ClickableWrapper } from 'componentsNewDesign/wrappers/ClicableWrapper';
import { Column } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { MarginWrapper } from 'componentsNewDesign/wrappers/grid/MarginWrapper';
import { useStore } from 'effector-react';
import { euroRate, totalCurrency } from 'pages/WithdrawalLimit/constants';
import { Bottom } from 'pages/WithdrawalLimit/SingleWithdrawalLimit/Bottom';
import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { locationEffects, locationStores } from 'stores/location/location';
import { withdrawalLimitsEffects, withdrawalLimitsStores } from 'stores/withdrawalLimit';
import { CloseImage, Container, CountryWrapper, EurWrapper, Input, InputWrapper, Wrapper } from './styles';

export const SingleWithdrawalLimit = () => {
    const { countries } = useStore(locationStores.countriesList);
    const { maxLimitInWom, regionId } = useStore(withdrawalLimitsStores.withdrawalLimitItem);
    const loading = useStore(withdrawalLimitsStores.loading);

    const [selectedCountry, setSelectedCountry] = useState<BULLZ.CountryResponse | undefined>();
    const [limit, setLimit] = useState<undefined | number>();
    const [checked, setChecked] = useState(false);

    const euro = limit && totalCurrency(limit, euroRate);
    const countriesList = countries ? [...countries].map(item => item.countryName).sort() : [''];
    const pathArray = history.location.pathname.split('/');
    const isUpdate = pathArray[pathArray.length - 1] !== 'create';
    const id = pathArray[pathArray.length - 1];
    const countryName = countries?.find(({ countryCode }) => countryCode === regionId)?.countryName;
    const defaultDisabled = !checked || !limit;
    const disabledButton = isUpdate ? defaultDisabled : defaultDisabled || !selectedCountry;

    const onLimitChange: ChangeEventHandler<HTMLInputElement> = e => {
        const regexForWholeNumber = /^[0-9\b]+$/;
        if (e.target.value === '' || regexForWholeNumber.test(e.target.value)) {
            setLimit(Number(e.target.value));
        }
    };

    const onCountryChange = (country: string) => {
        if (countries) {
            const selectedCountry = countries.find(({ countryName }) => countryName === country);
            setSelectedCountry(selectedCountry);
        }
    };

    const onGoBack = () => history.goBack();

    const resetValues = () => {
        setChecked(false);
        setLimit(undefined);
        setSelectedCountry(undefined);
    };

    const onCreateLimit = () => {
        withdrawalLimitsEffects.createWithdrawalLimit({
            regionCode: selectedCountry?.countryCode,
            maxLimitInWom: limit,
            countryName: countries?.find(({ countryCode }) => countryCode === selectedCountry?.countryCode)?.countryName
        });
        resetValues();
    };

    const onUpdateLimit = () => {
        withdrawalLimitsEffects.updateWithdrawalLimit({
            maxLimitInWom: limit,
            id,
            countryName
        });
        onGoBack();
    };

    const onCountryClose = () => setSelectedCountry(undefined);

    useEffect(() => {
        locationEffects.loadListOfCountries();
    }, []);

    useEffect(() => {
        if (isUpdate) {
            withdrawalLimitsEffects.getWithdrawalLimitQueryByID({ id });
        }
    }, [isUpdate, id]);

    useEffect(() => {
        if (isUpdate) {
            setLimit(maxLimitInWom);
        }
    }, [maxLimitInWom, isUpdate]);

    return (
        <MainLayout>
            <Container>
                <Wrapper>
                    <Section alignCenter>
                        <ClickableWrapper onClick={onGoBack}>
                            <CustomImg pointer height="14px" src={backArrowImg} width="14px" />
                        </ClickableWrapper>
                        <MarginWrapper marginLeft="15px">
                            <Span fontSize="17px" fontWeight="600" lineHeight="25px">
                                {isUpdate ? 'Update' : 'Create'} Withdrawal Limit
                            </Span>
                        </MarginWrapper>
                    </Section>
                    {loading ? (
                        <Section alignCenter>
                            <Loader />
                        </Section>
                    ) : (
                        <>
                            <Column marginBottom="15px">
                                <MarginWrapper marginBottom="11px">
                                    <Span color="dark-gray" fontSize="14px" lineHeight="21px">
                                        {isUpdate ? 'Update' : 'Set'} Limit
                                    </Span>
                                </MarginWrapper>
                                <InputWrapper withIcon>
                                    <Input step="1" type="number" value={limit || ''} onChange={onLimitChange} />
                                    <EurWrapper>{euro} Euros</EurWrapper>
                                </InputWrapper>
                            </Column>
                            <Column marginBottom="27px">
                                <MarginWrapper marginBottom="11px">
                                    <Span color="dark-gray" fontSize="14px" lineHeight="21px">
                                        Target Country
                                    </Span>
                                </MarginWrapper>
                                {isUpdate ? (
                                    <CountryWrapper untouchable>{countryName || ''}</CountryWrapper>
                                ) : selectedCountry ? (
                                    <CountryWrapper>
                                        {selectedCountry.countryName}
                                        <CloseImage src={closeButton} onClick={onCountryClose} />
                                    </CountryWrapper>
                                ) : (
                                    <InputWrapper>
                                        <SearchSelect
                                            itemsList={countriesList as string[]}
                                            placeholder="Add countries"
                                            onItemSelect={onCountryChange}
                                        />
                                    </InputWrapper>
                                )}
                            </Column>
                        </>
                    )}
                    <Bottom
                        checked={checked}
                        disabledButton={disabledButton}
                        isUpdate={isUpdate}
                        resetValues={resetValues}
                        setChecked={setChecked}
                        onCreateLimit={onCreateLimit}
                        onUpdateLimit={onUpdateLimit}
                    />
                </Wrapper>
            </Container>
        </MainLayout>
    );
};
