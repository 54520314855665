import { ItemSpan } from 'componentsNewDesign/common/inputs/Select/styles';
import { defaultMax, defaultMin } from 'componentsNewDesign/common/inputs/SocialNetworksFilter/constants';
import { Input, InputsWrapper } from 'componentsNewDesign/common/inputs/SocialNetworksFilter/RangeInputs/styles';
import { formatEngagementStatisticsValues } from 'componentsNewDesign/layouts/cards/VideoCard/constants';
import React from 'react';

interface Props {
    inputMax: number;
    inputMin: number;
}

export const RangeInputs = ({ inputMax, inputMin }: Props) => (
    <InputsWrapper>
        <Input
            readOnly
            max={defaultMax}
            min={defaultMin}
            placeholder="0k"
            value={formatEngagementStatisticsValues(inputMin)}
        />
        <ItemSpan>To</ItemSpan>
        <Input
            readOnly
            max={defaultMax}
            min={defaultMin}
            placeholder="10m"
            value={formatEngagementStatisticsValues(inputMax)}
        />
    </InputsWrapper>
);
