import arrowRight from 'assets/arrow_right.svg';
import { TotalBadge } from 'componentsNewDesign/common/badges/TotalBadge';
import {
    rightArrowIconHeight,
    rightArrowIconWidth,
    titlePadding
} from 'componentsNewDesign/common/dropdowns/SectionDropdown/constants';
import {
    DropdownSectionButton,
    DropdownSectionContent,
    DropdownSectionProps,
    DropdownSectionWrapper,
    TotalBadgeWrapper
} from 'componentsNewDesign/common/dropdowns/SectionDropdown/style';
import { CustomImg } from 'componentsNewDesign/common/imgComponents/CustomImg';
import { ContentText } from 'componentsNewDesign/common/typography/ContentText/styles';
import { Column } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { grey29, grey30 } from 'constants/styles/colors';
import { useToggle } from 'hooks/toggle';
import React, { FC } from 'react';
import { TotalRecords } from 'types/data';
import { DefaultValueBoolean } from 'types/form';

export const DropdownColumn: FC = ({ children }) => <Column width="100%">{children}</Column>;

export interface Props extends DropdownSectionProps, DefaultValueBoolean, TotalRecords {
    title: string;
    backgroundColor?: string;
    isBadge?: boolean;
}

export const DropdownSection: FC<Props> = ({
    children,
    totalRecords,
    isBadge,
    title,
    defaultValue = false,
    ...rest
}) => {
    const [isOpened, toggleIsOpened] = useToggle(defaultValue);

    return (
        <>
            {!isBadge ? (
                <DropdownSectionWrapper backgroundColor={grey30} isOpened={isOpened} {...rest}>
                    <DropdownSectionButton backgroundColor={grey29} onClick={toggleIsOpened} {...rest}>
                        <ContentText fontSize="16px" fontWeight="700" padding={titlePadding}>
                            {title}
                        </ContentText>
                        <CustomImg
                            alt="Arrow"
                            height={rightArrowIconHeight}
                            rotate={isOpened ? 90 : 0}
                            src={arrowRight}
                            width={rightArrowIconWidth}
                        />
                    </DropdownSectionButton>
                </DropdownSectionWrapper>
            ) : (
                <DropdownSectionContent isOpened={isOpened} {...rest}>
                    <DropdownSectionButton backgroundColor={grey29} onClick={toggleIsOpened} {...rest}>
                        <ContentText fontSize="16px" fontWeight="700" padding={titlePadding}>
                            {title}
                        </ContentText>
                        <CustomImg
                            alt="Arrow"
                            height={rightArrowIconHeight}
                            rotate={isOpened ? 90 : 0}
                            src={arrowRight}
                            width={rightArrowIconWidth}
                        />
                    </DropdownSectionButton>
                    <TotalBadgeWrapper>
                        <TotalBadge quantity={totalRecords && totalRecords !== -1 ? totalRecords : 0} />
                    </TotalBadgeWrapper>
                </DropdownSectionContent>
            )}
            {isOpened && children}
        </>
    );
};
