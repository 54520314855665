import noImageIcon from 'assets/no_product_icon.svg';
import history from 'browserHistory';
import { CustomImage } from 'componentsNewDesign/common/imgComponents/CustomImg/styles';
import { promotionsTableColumnTitles } from 'componentsNewDesign/common/tables/PromotionsTable/constants';
import {
    CellContentWrapper,
    Table,
    TableDataCell,
    TableHeaderCell,
    TableRow,
    TableWrapper
} from 'componentsNewDesign/common/tables/PromotionsTable/styles';
import { TableHeaderColumnSpan } from 'componentsNewDesign/common/tables/Table/styles';
import { ContentText } from 'componentsNewDesign/common/typography/ContentText/styles';
import { ClickableWrapper } from 'componentsNewDesign/wrappers/ClicableWrapper';
import { ContentWrapper } from 'componentsNewDesign/wrappers/ContentWrapper';
import { marketingToolsLink } from 'constants/routes';
import { errorColor, white } from 'constants/styles/colors';

import React from 'react';
import { DataTable } from 'types/data';

export interface PromotionsTableProps {
    items: BULLZ.GetAdminPromotionResponse[];
}

export const PromotionsTable = ({ items }: PromotionsTableProps) => {
    const dataTable: DataTable[] | undefined = items?.map(({ id, icon, pageLocation, isActive }) => {
        const onPromotionClick = (id: string) => history.push(marketingToolsLink + '/' + id);

        return {
            cells: [
                <CellContentWrapper key={id}>
                    <ClickableWrapper
                        onClick={() => {
                            id && onPromotionClick(id);
                        }}
                    >
                        <CustomImage src={icon || noImageIcon} width="30px" />
                    </ClickableWrapper>
                </CellContentWrapper>,
                <CellContentWrapper key={id}>
                    <ClickableWrapper
                        onClick={() => {
                            id && onPromotionClick(id);
                        }}
                    >
                        <ContentText>{id} </ContentText>
                    </ClickableWrapper>
                </CellContentWrapper>,

                <CellContentWrapper key={id}>
                    <ClickableWrapper
                        onClick={() => {
                            id && onPromotionClick(id);
                        }}
                    >
                        <ContentText>Promotion Name</ContentText>
                    </ClickableWrapper>
                </CellContentWrapper>,

                <CellContentWrapper key={id}>
                    <ClickableWrapper
                        onClick={() => {
                            id && onPromotionClick(id);
                        }}
                    >
                        <ContentText>{pageLocation}</ContentText>
                    </ClickableWrapper>
                </CellContentWrapper>,

                <CellContentWrapper key={id}>
                    <ClickableWrapper
                        onClick={() => {
                            id && onPromotionClick(id);
                        }}
                    >
                        <ContentWrapper
                            backgroundColor={isActive ? '#29E20A' : errorColor}
                            borderRadius="50%"
                            height="10px"
                            minWidth="10px"
                        />
                    </ClickableWrapper>
                </CellContentWrapper>
            ]
        };
    });
    return (
        <TableWrapper>
            <Table>
                <TableRow>
                    {promotionsTableColumnTitles.map(title => (
                        <TableHeaderCell key={title}>
                            <CellContentWrapper>
                                <TableHeaderColumnSpan color={white}>{title}</TableHeaderColumnSpan>
                            </CellContentWrapper>
                        </TableHeaderCell>
                    ))}
                </TableRow>

                {dataTable?.map(({ cells }, index) => (
                    <TableRow key={index.toString()}>
                        {cells.map((cell, index) => (
                            <TableDataCell key={index.toString()}>{cell}</TableDataCell>
                        ))}
                    </TableRow>
                ))}
            </Table>
        </TableWrapper>
    );
};
