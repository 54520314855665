import { Loader } from 'components/common/dynamic/Loader';
import { StatisticsTable } from 'components/common/tables/StatisticsTable';
import { AdministratorLayout } from 'components/layouts/RolesLayouts';
import { DropdownColumn, DropdownSection } from 'componentsNewDesign/common/dropdowns/SectionDropdown';
import { UserDescription, UserVideos } from 'componentsNewDesign/layouts/descriptionLayouts/UserDescription';
import { VideoCardFilterLayout } from 'componentsNewDesign/layouts/filterLayouts/VideoCardFilterLayout';
import { Pagination } from 'componentsNewDesign/layouts/Pagination';
import { Empty } from 'componentsNewDesign/layouts/resultLayouts/Empty';
import { SingleMainLayout } from 'componentsNewDesign/layouts/SingleMainLayout';
import { AddInfluencerToAgentModal } from 'componentsNewDesign/modals/filterModals/AddInfluencerToAgentModal';
import { AssignAgentModal } from 'componentsNewDesign/modals/filterModals/AssignAgentModal';
import { Section } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { defaultLimit } from 'constants/defaults/filterSettings';
import { agentRole, defaultUserVideosValuesWithoutDate, Roles } from 'constants/defaults/users';
import { xs } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import { AgentInfluencers } from 'pages/Users/User/AgentInfluencers';
import { notFoundMessage, referralsLimit } from 'pages/Users/User/constants';
import { DropdownReferral } from 'pages/Users/User/DropdownReferral';
import { UserReferralTable } from 'pages/Users/User/UserReferralsTable';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import { statisticsEvents, statisticsStores } from 'stores/statistics/statistics';
import { referralEffects, referralStores } from 'stores/users/referrals';
import { userStores } from 'stores/users/user';
import { usersEffects, usersStores } from 'stores/users/users';
import { userVideosEffects, userVideosEvents, userVideosStores } from 'stores/users/userVideos';
import { ColumnWrapper, PaginationWrapper, ReferralWrapper } from './styles';

const { updateValues, setIsFirstToTrue } = userVideosEvents;

interface ParamsProps {
    userId: string;
}

const { getUserReferralLinksFx } = referralEffects;
const { $userReferralLinks } = referralStores;

export const User = () => {
    const { userId } = useParams<ParamsProps>();
    const user = useStore(usersStores.user);
    const loading = useStore(usersStores.singleItemLoading);

    const referralInfo = user.referralInfo;
    const referralId = referralInfo?.id;

    const loadingReferral = useStore(getUserReferralLinksFx.pending);

    const { data } = useStore($userReferralLinks);

    const [checked, setChecked] = useState(false);
    const isMobile = useMediaQuery({ query: `(max-width: ${xs})` });

    const userVideos = useStore(userVideosStores.userVideos);
    const userVideosLoading = useStore(userVideosStores.initialLoading);

    const loadingStatistics = useStore(statisticsStores.initialLoading);
    const { items: itemsStatistics, totalRecords: totalRecordsStatistics } = useStore(statisticsStores.statistics);
    const { pageIndex: statisticsPageIndex, limit: statisticsLimit } = useStore(statisticsStores.values);
    const { access } = useStore(userStores.auth);

    const userVideosTitle = userVideosLoading ? `User's Videos` : `User's Videos (${userVideos.totalRecords || 0})`;

    const onStatisticsCurrentPageChange = (page: number, pageSize: number | undefined) =>
        statisticsEvents.updateValues({
            pageIndex: page,
            limit: pageSize || defaultLimit
        });

    const onChangeCheckbox = () => {
        setChecked(!checked);
    };

    useEffect(() => {
        usersEffects.loadSingleItemById(userId);
        updateValues({
            ...defaultUserVideosValuesWithoutDate,
            creatorId: userId
        });
        setIsFirstToTrue();
        access < Roles.ContentManager &&
            statisticsEvents.overrideValues({
                userId: userId
            });
    }, [userId, access]);

    useEffect(() => {
        if (checked) {
            userVideosEffects.loadItems({
                creatorId: userId,
                limit: 20,
                pageIndex: 0,
                returnQueryCount: true
            });
        } else {
            userVideosEffects.loadItems({
                creatorId: userId,
                hasHlsStream: true,
                isDeleted: false,
                isReported: false,
                limit: 20,
                pageIndex: 0,
                returnQueryCount: true
            });
        }
    }, [userId, checked]);

    useEffect(() => {
        referralId && getUserReferralLinksFx(referralId);
    }, [referralId]);

    return (
        <SingleMainLayout>
            {loading ? (
                <Section justifyCenter>
                    <Loader size="large" />
                </Section>
            ) : (
                <>
                    <Section marginBottom="8px">
                        {user?.id ? <UserDescription {...user} /> : <Empty title={notFoundMessage} />}
                    </Section>
                    {user.roles?.includes(agentRole) && (
                        <Section marginBottom="8px">
                            <AgentInfluencers />
                        </Section>
                    )}
                    <Section marginBottom="8px">
                        <AdministratorLayout>
                            <DropdownColumn>
                                <DropdownSection title="User statistics">
                                    {loadingStatistics ? (
                                        <Section justifyCenter>
                                            <Loader size="large" />
                                        </Section>
                                    ) : (
                                        <>
                                            <Section marginBottom="20px">
                                                <StatisticsTable items={itemsStatistics} removeItem="userId" />
                                            </Section>
                                            <Pagination
                                                currentIndex={statisticsPageIndex + 1}
                                                defaultSize={statisticsLimit}
                                                totalItems={totalRecordsStatistics}
                                                onSizeChange={onStatisticsCurrentPageChange}
                                            />
                                        </>
                                    )}
                                </DropdownSection>
                            </DropdownColumn>
                        </AdministratorLayout>
                    </Section>

                    {referralInfo?.referralLink && (
                        <ReferralWrapper marginBottom="8px">
                            <ColumnWrapper width="100%">
                                <DropdownReferral
                                    downloadCount={referralInfo?.downloadsCount}
                                    referralLink={referralInfo?.referralLink}
                                    registeredCount={referralInfo?.signUpsCount}
                                    title="User Referrals"
                                >
                                    {loadingReferral ? (
                                        <Section justifyCenter>
                                            <Loader size="large" />
                                        </Section>
                                    ) : (
                                        data?.items &&
                                        data?.items.length > 0 && (
                                            <>
                                                <Section marginBottom="20px" marginTop="16px">
                                                    <UserReferralTable data={data} />
                                                </Section>
                                                <PaginationWrapper>
                                                    <Pagination
                                                        currentIndex={(data?.pageIndex || 0) + 1}
                                                        defaultSize={referralsLimit}
                                                        totalItems={data?.itemsCount}
                                                        onSizeChange={onStatisticsCurrentPageChange}
                                                    />
                                                </PaginationWrapper>
                                            </>
                                        )
                                    )}
                                </DropdownReferral>
                            </ColumnWrapper>
                        </ReferralWrapper>
                    )}

                    <DropdownColumn>
                        <DropdownSection title={userVideosTitle}>
                            {loading || userVideosLoading ? (
                                <Section justifyCenter marginBottom="20px">
                                    <Loader size="large" />
                                </Section>
                            ) : (
                                <VideoCardFilterLayout
                                    checkboxShowAll
                                    defaultChecked={checked}
                                    totalRecords={userVideos.totalRecords}
                                    onChangeCheckbox={onChangeCheckbox}
                                >
                                    <Section marginBottom={isMobile ? '50px' : '0'}>
                                        <UserVideos isUserVideos videos={userVideos?.items || undefined} />
                                    </Section>
                                </VideoCardFilterLayout>
                            )}
                        </DropdownSection>
                    </DropdownColumn>
                </>
            )}
            <AssignAgentModal />
            <AddInfluencerToAgentModal />
        </SingleMainLayout>
    );
};
