import { formatEngagementStatisticsValues } from 'componentsNewDesign/layouts/cards/VideoCard/constants';
import { calculateBlockWidth } from 'componentsNewDesign/layouts/descriptionLayouts/UserDescription/utils';
import { differenceInYears } from 'date-fns';

export const backImgDiameter = '15px';

export const primaryMargin = '8px';
export const secondaryMargin = '16px';
export const subtitleFontSize = '14px';
export const cardButtonWidth = 'fit-content';
export const cardButtonHeight = '30px';

export const propertyBlockDesktopWidth = '232px';
export const propertyBlockLaptopWidth = calculateBlockWidth(2, 8);

export const getAge = (birthDate?: string) => {
    if (!birthDate) return '';

    const milliseconds = Date.parse(birthDate);
    if (!milliseconds || milliseconds < 0) return '';

    const age = differenceInYears(new Date(), new Date(birthDate)).toString();

    return age;
};

// External networks keys
export enum ExternalNetworkKey {
    Bullz = 'bullz',
    Instagram = 'instagram',
    Twitter = 'twitter',
    Youtube = 'youtube',
    TikTok = 'tiktok',
    Twitch = 'twitch'
}

interface NetworkLinkProps {
    [key: string]: string;
}

export const networkLink: NetworkLinkProps = {
    [ExternalNetworkKey.Instagram]: 'https://instagram.com/',
    [ExternalNetworkKey.Twitter]: 'https://twitter.com/',
    [ExternalNetworkKey.Youtube]: 'https://www.youtube.com/channel/',
    [ExternalNetworkKey.TikTok]: 'https://www.tiktok.com/',
    [ExternalNetworkKey.Twitch]: 'https://www.twitch.com/'
};
const externalNetworks = [
    ExternalNetworkKey.Twitter,
    ExternalNetworkKey.Instagram,
    ExternalNetworkKey.Youtube,
    ExternalNetworkKey.TikTok,
    ExternalNetworkKey.Twitch
];

export interface Networks {
    name: string;
    linked: boolean;
    engagement: string;
    link: string;
    id: string;
}

export const getNetworksData = (
    userNetworks: BULLZ.GetUserExternalNetworkResponse[] | null | undefined
): Networks[] | undefined => {
    const data = externalNetworks.map(network => {
        const userNetworkData = userNetworks?.find(({ networkName }) => networkName?.toLowerCase() === network);

        if (userNetworkData && userNetworkData.remoteUserId) {
            const { networkName, engagement, remoteUserId, username } = userNetworkData;

            const formattedCount = formatEngagementStatisticsValues(engagement?.followersCount || 0);

            const followersCount =
                networkName?.toLocaleLowerCase() === ExternalNetworkKey.Instagram
                    ? 'Unavailable'
                    : networkName?.toLocaleLowerCase() === ExternalNetworkKey.Youtube
                    ? `${formattedCount} ${engagement?.followersCount === 1 ? 'Subscriber' : 'Subscribers'}`
                    : `${formattedCount} ${engagement?.followersCount === 1 ? 'Follower' : 'Followers'}`;

            const userPageLink =
                networkName && username ? `${networkLink[networkName.toLocaleLowerCase()]}${username}` : '';

            return {
                name: network,
                linked: !!remoteUserId,
                engagement: followersCount,
                link: userPageLink,
                id: remoteUserId
            };
        }
        return {
            name: network,
            linked: false,
            engagement: '-',
            link: '',
            id: ''
        };
    });

    return data;
};
