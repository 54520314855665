import { grey23, grey29, grey28 } from 'constants/styles/colors';
import styled from 'styled-components';
import { Section, Column } from 'componentsNewDesign/wrappers/grid/FlexWrapper';

export const CheckboxWrapper = styled.div`
    width: 170px;
    height: 59px;
    border-radius: 8px;
    transition: background-color 0.3s;

    :hover {
        background-color: ${grey23};
    }
`;

export const ReferralWrapper = styled(Section)`
    background-color: ${grey29};
    padding-bottom: 20px;
    border-radius: 8px;
`;

export const ColumnWrapper = styled(Column)`
    padding: 16px;
`;

export const PaginationWrapper = styled(Section)`
    padding: 18px;
    background-color: ${grey28};
    border-radius: 0 0 8px 8px;
`;
