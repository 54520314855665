export const limitColumns = [
    {
        title: 'Limit ID',
        dataIndex: 'displayId',
        key: 'displayId',
        width: 5
    },
    {
        title: 'Withdrawal Limit',
        dataIndex: 'limit',
        key: 'limit',
        width: 3
    },
    {
        title: 'Euros',
        dataIndex: 'euro',
        key: 'euro',
        width: 2
    },
    {
        title: 'Locations',
        dataIndex: 'location',
        key: 'location',
        width: 3
    },
    {
        title: '',
        dataIndex: 'delete',
        key: 'delete',
        width: 5
    }
];

export const sumPartsOfTableWidth = limitColumns.map(({ width }) => width).reduce((prev, curr) => prev + curr, 0);
export const getColumnWidth = (width: number) => (width * 100) / sumPartsOfTableWidth + '%';
