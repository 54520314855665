import { useMediaQuery } from '@material-ui/core';
import { ResetSearchButton } from 'componentsNewDesign/common/buttons/ResetButton';
import { CheckboxFilter, TrendingsCheckboxFilter } from 'componentsNewDesign/common/inputs/CheckboxFilter';
import { NestedSelect } from 'componentsNewDesign/common/inputs/NestedSelect';
import { FilterParameters, selectorsArray } from 'componentsNewDesign/common/inputs/NestedSelect/constants';
import { SearchInput } from 'componentsNewDesign/common/inputs/SearchInput';
import { Select } from 'componentsNewDesign/common/inputs/Select';
import { TrendingsNestedSelect } from 'componentsNewDesign/common/inputs/TrendingsNestedSelect';
import { TrendingsSearchInput } from 'componentsNewDesign/common/inputs/TrendingsSearchInput';
import { Footer } from 'componentsNewDesign/grid/Footer';
import { SearchWrapperLayout } from 'componentsNewDesign/layouts/blocks/SearchWrapperLayout';
import {
    defaultSearchParameters,
    LocaleSelectorProps,
    searchUserByEmailParameter,
    searchUserByIdParameter,
    searchUserByMobileNumberParameter,
    SelectorKeyType
} from 'componentsNewDesign/layouts/filterLayouts/UsersFilterLayout/constants';
import { Pagination } from 'componentsNewDesign/layouts/Pagination';
import { Column, FlexGrow, Row, Section } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { MarginWrapper } from 'componentsNewDesign/wrappers/grid/MarginWrapper';
import { defaultLimit, defaultPage } from 'constants/defaults/filterSettings';
import { agentRole, Roles, sortTagsUsersData, sortTagsUsersValues } from 'constants/defaults/users';
import { mongoDbObjectIdRegExp } from 'constants/regularExpressions';
import { filterMargin, xs } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import {
    emailRegExp,
    emailSearchPlaceholder,
    mobileNumberRegExp,
    mobileNumberSearchPlaceholder,
    userIdSearchPlaceholder,
    usernameSearchPlaceholder
} from 'pages/Users/constants';
import React, { FC, useEffect } from 'react';
import { locationEffects, locationStores } from 'stores/location/location';
import { usersEffects, usersEvents, usersStores } from 'stores/users/users';
import { SearchParameters, TotalRecords } from 'types/data';

const { setId, updateValues, setIsFirstToFalse, resetUsers, setDefaultValues, setIsFirstToTrue } = usersEvents;
const { loadItemById } = usersEffects;
const { loadListOfCountries, loadListOfRegionsByCountry } = locationEffects;

export const FilterLayout: FC<TotalRecords> = ({ totalRecords, children }) => {
    const isFirst = useStore(usersStores.isFirst);
    const { pageIndex, limit, username, isTrusted, email, mobileNumber, country, region, locale, role } = useStore(
        usersStores.values
    );
    const defaultSelectedItemType = country ? 'country' : region ? 'region' : 'locale';
    const location = useStore(locationStores.locationList);
    const regionsListIsLoading = useStore(locationEffects.loadListOfRegionsByCountry.pending);
    const isMobile = useMediaQuery(`(max-width: ${xs})`);
    const defaultId = useStore(usersStores.getRequestId);
    const defaultMemberSearchParams = { ...defaultSearchParameters, isAgent: false, isAgentMember: false };
    const sortValues = sortTagsUsersValues.filter(value => value !== agentRole);
    const sortData = sortTagsUsersData.filter(value => value !== agentRole);

    const onUsernameSearch = (name: string) => {
        setId('');
        updateValues({
            ...defaultMemberSearchParams,
            username: name,
            pageIndex: defaultPage
        });
    };

    const onIdSearch = (id: string) => {
        setId(id);
        if (id) {
            updateValues({
                ...defaultMemberSearchParams
            });
            loadItemById(id);
        } else {
            updateValues({
                pageIndex: defaultPage
            });
        }
    };

    const onEmailSearch = (email: string) => {
        setId('');
        updateValues({
            ...defaultMemberSearchParams,
            email: email || undefined,
            pageIndex: defaultPage
        });
    };

    const onMobileNumberSearch = (mobileNumber: string) => {
        setId('');
        updateValues({
            ...defaultMemberSearchParams,
            mobileNumber: mobileNumber || undefined,
            pageIndex: defaultPage
        });
    };

    const onSortChange = (index: number) =>
        updateValues({
            role: sortValues[index],
            pageIndex: defaultPage
        });

    const onTrustedChange = (isTrusted: boolean) => {
        updateValues({
            ...defaultMemberSearchParams,
            isTrusted: isTrusted ?? undefined,
            pageIndex: defaultPage
        });
    };

    const onLocaleSelect = ({ selectorType, selectorName, selectorCode }: FilterParameters) => {
        const newValues: LocaleSelectorProps = { country: '', region: '', locale: '' };

        Object.keys(newValues);

        let key: SelectorKeyType;

        for (key in newValues) {
            key === selectorType && (newValues[key] = selectorCode || selectorName);
        }

        setId('');
        updateValues({
            ...defaultMemberSearchParams,
            ...newValues,
            pageIndex: defaultPage
        });
    };

    const resetFilters = () => {
        updateValues({
            ...defaultMemberSearchParams,
            pageIndex: defaultPage
        });
    };

    const onCurrentPageChange = (page: number, pageSize: number | undefined) =>
        updateValues({
            pageIndex: page,
            limit: pageSize || defaultLimit
        });

    const searchParameters: SearchParameters[] = [
        {
            searchBy: 'Username',
            defaultValue: username,
            placeholder: usernameSearchPlaceholder,
            onSearch: onUsernameSearch
        },
        {
            searchBy: searchUserByIdParameter,
            defaultValue: defaultId,
            placeholder: userIdSearchPlaceholder,
            onSearch: onIdSearch,
            regExp: mongoDbObjectIdRegExp
        },
        {
            searchBy: searchUserByEmailParameter,
            defaultValue: email,
            placeholder: emailSearchPlaceholder,
            onSearch: onEmailSearch,
            accessFilter: [Roles.SuperAdministrator],
            regExp: emailRegExp
        },
        {
            searchBy: searchUserByMobileNumberParameter,
            defaultValue: mobileNumber,
            placeholder: mobileNumberSearchPlaceholder,
            onSearch: onMobileNumberSearch,
            accessFilter: [Roles.SuperAdministrator],
            regExp: mobileNumberRegExp
        }
    ];

    useEffect(() => {
        if (isFirst) {
            updateValues({
                ...defaultMemberSearchParams,
                pageIndex: defaultPage
            });
            setIsFirstToFalse();
            loadListOfCountries();
        }
        return () => {
            resetUsers();
            setDefaultValues();
            setIsFirstToTrue();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const selectors = location
        ? selectorsArray(
              location.map(({ countryName, countryCode, region }) => ({
                  selectorName: countryName || '',
                  selectorCode: countryCode || '',
                  selectorType: 'country',
                  isFetched: true,
                  onFetch: value => loadListOfRegionsByCountry({ country: value }),
                  nestedSelectors: region
                      ? region.map(region => ({
                            selectorName: region,
                            selectorType: 'region'
                        }))
                      : undefined
              }))
          )
        : [];

    return !isMobile ? (
        <>
            <SearchWrapperLayout alignCenter>
                <FlexGrow flexGrow="1" marginRight={filterMargin}>
                    <SearchInput searchParameters={searchParameters} />
                </FlexGrow>
                <MarginWrapper marginRight={filterMargin}>
                    <Select
                        defaultIndex={sortValues.findIndex(item => item === role)}
                        selector={sortData}
                        title="Filter by role"
                        width="182px"
                        onChange={onSortChange}
                    />
                </MarginWrapper>
                <MarginWrapper marginRight={filterMargin}>
                    <NestedSelect
                        defaultSelectedItem={country || region || locale || undefined}
                        defaultSelectedItemType={defaultSelectedItemType}
                        isLoading={regionsListIsLoading}
                        selector={selectors}
                        title="Filter by locale or country"
                        width="300px"
                        onSelect={onLocaleSelect}
                    />
                </MarginWrapper>
                <Row alignCenter marginRight="20px">
                    <CheckboxFilter defaultChecked={isTrusted || undefined} onChange={onTrustedChange}>
                        Is trusted
                    </CheckboxFilter>
                </Row>

                <ResetSearchButton onClick={resetFilters} />
            </SearchWrapperLayout>
            {children}
            <Footer>
                <Pagination
                    currentIndex={pageIndex + 1}
                    defaultSize={limit}
                    pagesLimit={100}
                    totalItems={totalRecords}
                    onSizeChange={onCurrentPageChange}
                />
            </Footer>
        </>
    ) : (
        <>
            <Section alignCenter>
                <TrendingsSearchInput searchParameters={searchParameters} />
                <Column alignCenter width="100%">
                    <MarginWrapper>
                        <Select
                            defaultIndex={sortValues.findIndex(item => item === role)}
                            selector={sortData}
                            title="Filter by role"
                            width="182px"
                            onChange={onSortChange}
                        />
                    </MarginWrapper>
                    <TrendingsNestedSelect
                        backgroundColor="transparent"
                        defaultSelectedItem={country || region || locale || undefined}
                        defaultSelectedItemType={defaultSelectedItemType}
                        isLoading={regionsListIsLoading}
                        selector={selectors}
                        title="Filter by locale or country"
                        width="100%"
                        onSelect={onLocaleSelect}
                    />
                    <Section alignCenter justifyEnd>
                        <TrendingsCheckboxFilter defaultChecked={isTrusted || undefined} onChange={onTrustedChange}>
                            Only is trusted
                        </TrendingsCheckboxFilter>
                        <ResetSearchButton fontSize="11px" lineHeight="13px" onClick={resetFilters} />
                    </Section>
                </Column>
            </Section>

            {children}
            <Footer>
                <Pagination
                    currentIndex={pageIndex + 1}
                    defaultSize={limit}
                    pagesLimit={100}
                    totalItems={totalRecords}
                    onSizeChange={onCurrentPageChange}
                />
            </Footer>
        </>
    );
};
