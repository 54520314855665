import yeayLogo from 'assets/bullz_logo_white.svg';
import { SimpleButton } from 'componentsNewDesign/common/buttons/SimpleButton';
import { CustomImg } from 'componentsNewDesign/common/imgComponents/CustomImg';
import { Span } from 'componentsNewDesign/common/typography/Span';
import { AbsoluteWrapper } from 'componentsNewDesign/wrappers/grid/AbsoluteWrapper';
import { Column, Row } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { black, grey27, white } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import { useNonScrolledBackground } from 'hooks/nonScrolledBackground';
import React from 'react';
import { creationLimitModal } from 'stores/initialize/initialize.modal.store';
import { Modal, ModalContentWrapper, Triangle, Wrapper } from './styles';

const { modal, closeModal } = creationLimitModal;

export const CreationLimitModal = () => {
    const [visible, { title, description, onOk, okButtonText }] = useStore(modal);

    const onDoneClick = () => {
        onOk?.();
        closeModal();
    };

    useNonScrolledBackground(visible);

    return (
        <Wrapper visible={visible}>
            <Modal>
                <AbsoluteWrapper right="0px" top="0px" width="100%" zIndex="5">
                    <Column alignCenter marginTop="60px">
                        <CustomImg src={yeayLogo} width="180px" />
                    </Column>
                </AbsoluteWrapper>
                <Triangle />
                <ModalContentWrapper>
                    <Row>
                        <Span alignCenter color={black} fontSize="32px" fontWeight="700" lineHeight="35px">
                            {title}
                        </Span>
                    </Row>
                    <Column alignCenter marginBottom="30px" width="400px">
                        <Span alignCenter color="#333333" fontSize="17px" fontWeight="400">
                            {description}
                        </Span>
                    </Column>

                    <SimpleButton
                        background={black}
                        backgroundHover={grey27}
                        borderRadius="4px"
                        color={white}
                        fontSize="16px"
                        fontWeight="400"
                        marginBottom="40px"
                        padding="8px"
                        textHover={white}
                        width="100px"
                        onClick={onDoneClick}
                    >
                        {okButtonText}
                    </SimpleButton>
                </ModalContentWrapper>
            </Modal>
        </Wrapper>
    );
};
