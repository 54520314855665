import { ResetSearchButton } from 'componentsNewDesign/common/buttons/ResetButton';
import { SaveCSVButton } from 'componentsNewDesign/common/buttons/SaveCSVButton';
import { SearchInput } from 'componentsNewDesign/common/inputs/SearchInput';
import { Loader } from 'componentsNewDesign/dynamic/Loader';
import { Footer } from 'componentsNewDesign/grid/Footer';
import { Pagination } from 'componentsNewDesign/layouts/Pagination';
import { Section } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { defaultLimit } from 'constants/defaults/filterSettings';
import { mongoDbObjectIdRegExp } from 'constants/regularExpressions';
import { useStore } from 'effector-react';
import { euroRate, totalCurrency } from 'pages/WithdrawalLimit/constants';
import { csvFilename, headerForCSVTable } from 'pages/WithdrawalLimit/FilterLayout/constants';
import { SearchWrapper, TopWrapper } from 'pages/WithdrawalLimit/FilterLayout/styles';
import React, { FC } from 'react';
import { locationStores } from 'stores/location/location';
import {
    defaultWithdrawalLimits,
    withdrawalLimitsEffects,
    withdrawalLimitsEvents,
    withdrawalLimitsStores
} from 'stores/withdrawalLimit';
import { SearchParameters } from 'types/data';

const { values, withdrawalLimitsQuery, loading } = withdrawalLimitsStores;
const { getWithdrawalLimitQueryByID, getWithdrawalLimitQuery } = withdrawalLimitsEffects;
const { updateValues } = withdrawalLimitsEvents;

export const FilterLayout: FC = ({ children }) => {
    const { limit, pageIndex } = useStore(values);
    const { totalRecords, items } = useStore(withdrawalLimitsQuery);
    const { countries } = useStore(locationStores.countriesList);
    const isLoading = useStore(loading);

    const csvData = items
        ? items.map(({ id, regionId, maxLimitInWom }) => ({
              id,
              maxLimitInWom,
              euro: maxLimitInWom ? totalCurrency(maxLimitInWom, euroRate) : 0,
              regionId: countries ? countries.find(({ countryCode }) => countryCode === regionId)?.countryName : ''
          }))
        : [];

    const onLimitIdSearch = (id: string) => {
        if (id) {
            getWithdrawalLimitQueryByID({ id });
        } else {
            getWithdrawalLimitQuery({
                ...defaultWithdrawalLimits,
                pageIndex: pageIndex || defaultWithdrawalLimits.pageIndex,
                limit: limit || defaultWithdrawalLimits.limit
            });
        }
    };

    const onCurrentPageChange = (page: number, pageSize: number | undefined) =>
        updateValues({
            pageIndex: page,
            limit: pageSize || defaultLimit
        });

    const searchParameters: SearchParameters[] = [
        {
            searchBy: 'Limit ID',
            defaultValue: '',
            placeholder: 'Search by Limit ID',
            onSearch: onLimitIdSearch,
            regExp: mongoDbObjectIdRegExp
        }
    ];

    const resetFilters = () => {
        getWithdrawalLimitQuery({
            ...defaultWithdrawalLimits
        });
    };

    return (
        <>
            <TopWrapper>
                <SearchWrapper noWrap>
                    <SearchInput searchParameters={searchParameters} />
                    <ResetSearchButton onClick={resetFilters} />
                </SearchWrapper>
                <SaveCSVButton data={csvData} disabled={isLoading} filename={csvFilename()} headers={headerForCSVTable}>
                    {isLoading ? (
                        <Section alignCenter justifyCenter>
                            <Loader />
                        </Section>
                    ) : (
                        'Download Results as CSV'
                    )}
                </SaveCSVButton>
            </TopWrapper>
            {children}
            <Footer>
                <Pagination
                    currentIndex={pageIndex + 1}
                    defaultSize={limit}
                    pagesLimit={100}
                    totalItems={totalRecords}
                    onSizeChange={onCurrentPageChange}
                />
            </Footer>
        </>
    );
};
