import arrowRight from 'assets/arrow_right.svg';
import whiteCopyIcon from 'assets/copy_icon_white.svg';
import { CustomImg } from 'componentsNewDesign/common/imgComponents/CustomImg';
import { ContentText } from 'componentsNewDesign/common/typography/ContentText/styles';
import { PropertyBlock } from 'componentsNewDesign/layouts/blocks/PropertyBlock';
import { Section } from 'componentsNewDesign/wrappers/grid/FlexWrapper/styles';
import { grey27 } from 'constants/styles/colors';
import { useToggle } from 'hooks/toggle';
import { DropdownSectionButton, DropdownSectionWrapper } from 'pages/Users/User/DropdownReferral/styles';
import React, { FC } from 'react';
import { DefaultValueBoolean } from 'types/form';

export interface Props extends DefaultValueBoolean {
    title: string;
    referralLink?: string;
    registeredCount?: number;
    downloadCount?: number;
    isOpened?: boolean;
}

export const DropdownReferral: FC<Props> = ({
    children,
    title,
    defaultValue = false,
    referralLink,
    registeredCount,
    downloadCount
}) => {
    const [isOpened, toggleIsOpened] = useToggle(defaultValue);

    return (
        <>
            <DropdownSectionWrapper>
                <DropdownSectionButton type="button" onClick={toggleIsOpened}>
                    <Section alignCenter justifyBetween noWrap marginBottom="16px">
                        <ContentText fontSize="14px" fontWeight="500" lineHeight="16px">
                            {title}
                        </ContentText>
                        <CustomImg alt="Arrow" height="12px" rotate={isOpened ? 90 : 0} src={arrowRight} width="7px" />
                    </Section>
                </DropdownSectionButton>

                <Section>
                    <PropertyBlock
                        copiable
                        notEllipsisSubtitle
                        titleUppercase
                        backgroundColor={grey27}
                        customCopyIcon={whiteCopyIcon}
                        marginRight="8px"
                        subtitle={referralLink || ''}
                        success="Referral link copied!"
                        title="user referral link"
                        width="230px"
                    />

                    <PropertyBlock
                        titleUppercase
                        backgroundColor={grey27}
                        marginRight="8px"
                        subtitle={registeredCount?.toString()}
                        title="total registered count"
                        width="230px"
                    />

                    <PropertyBlock
                        titleUppercase
                        backgroundColor={grey27}
                        subtitle={downloadCount?.toString()}
                        title="total download count"
                        width="230px"
                    />
                </Section>
            </DropdownSectionWrapper>

            {isOpened && children}
        </>
    );
};
