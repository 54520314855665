import { ClickableWrapper } from 'componentsNewDesign/wrappers/ClicableWrapper';
import { black } from 'constants/styles/colors';
import { transitionTime } from 'constants/styles/others';
import { xs } from 'constants/styles/sizes';
import styled from 'styled-components';
import { Active } from 'types/global';

export const StyledList = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const ListItem = styled.li`
    font-size: 12px;
    line-height: 13px;
`;

export const ItemWrapper = styled(ClickableWrapper)<Active>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: noWrap;
    width: 100%;
    padding: 7px 10px 7px 12px;
    transition: background-color ${transitionTime};
    cursor: pointer;

    &:hover {
        background-color: ${black};
    }

    @media (max-width: ${xs}) {
        max-width: 100%;
        padding: 7px 22px 7px 20px;
    }
`;
