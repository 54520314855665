import backArrowImg from 'assets/back_arrow.svg';
import whiteCopyIcon from 'assets/copy_icon_white.svg';
import history from 'browserHistory';
import { AdministratorLayout, SuperAdministratorLayout } from 'components/layouts/RolesLayouts';
import { CardButton } from 'componentsNewDesign/common/buttons/CardButton';
import { AvatarImg } from 'componentsNewDesign/common/imgComponents/AvatarImg';
import { CustomImg } from 'componentsNewDesign/common/imgComponents/CustomImg';
import { localeSelector } from 'componentsNewDesign/common/inputs/NestedSelect/constants';
import { ClosableTag } from 'componentsNewDesign/common/tags/ClosableTag';
import { ContentText } from 'componentsNewDesign/common/typography/ContentText/styles';
import { ChangeablePropertyBlock, PropertyBlock } from 'componentsNewDesign/layouts/blocks/PropertyBlock';
import {
    assignRoleTitle,
    assigningTitle,
    copyEmailMessage,
    copyFacilitatorIdMessage,
    copyPhoneMessage,
    copyUserIdMessage,
    copyUsernameMessage,
    parseAssignSuccessMessage,
    parseDisableDescription,
    parseDisableSuccessMessage,
    parseDisableTitle,
    parseRemoveRoleDescription,
    parseRemoveRoleSuccessMessage,
    parseVerifyDescription,
    parseVerifySuccessMessage,
    parseVerifyTitle,
    removeRoleTitle,
    rolesAbsentMessage
} from 'componentsNewDesign/layouts/cards/UserCard/constants';
import { VideoCard } from 'componentsNewDesign/layouts/cards/VideoCard';
import { AgentSection } from 'componentsNewDesign/layouts/descriptionLayouts/UserDescription/AgentSection';
import {
    backImgDiameter,
    cardButtonHeight,
    cardButtonWidth,
    getAge,
    getNetworksData,
    primaryMargin,
    propertyBlockDesktopWidth,
    propertyBlockLaptopWidth,
    secondaryMargin,
    subtitleFontSize
} from 'componentsNewDesign/layouts/descriptionLayouts/UserDescription/constants';
import { Empty } from 'componentsNewDesign/layouts/resultLayouts/Empty';
import { deleteReasonsList } from 'componentsNewDesign/modals/DeleteOrBlockUserModal/constants';
import { RolesPopover } from 'componentsNewDesign/modals/popovers/RolesPopover';
import { ContentWrapper } from 'componentsNewDesign/wrappers/ContentWrapper';
import { Column, Row, Section } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { MarginWrapper } from 'componentsNewDesign/wrappers/grid/MarginWrapper';
import {
    Roles,
    agentMemberRole,
    agentRole,
    assignedUserRoles,
    assignedUserRolesForSuperAdmin,
    defaultUserRoles,
    userGender
} from 'constants/defaults/users';
import { asyncError, videosNotFoundMessage } from 'constants/notifications';
import { usersLink } from 'constants/routes';
import { getRedirectUrlToWom } from 'constants/services';
import { blue, errorColor, grey27, grey29, hoverGrey2, white } from 'constants/styles/colors';
import { lg_1 } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import React, { useEffect, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { API } from 'services';
import { agentEffects } from 'stores/agent';
import { message } from 'stores/alerts';
import { infoModal } from 'stores/initialize/initialize.modal.store';
import { modalEvents } from 'stores/modals/asyncModal';
import { userStores } from 'stores/users/user';
import { usersEffects, usersEvents, usersStores } from 'stores/users/users';
import { SubjectType } from 'types/types';
import { parseAssignRoleDescription } from 'utils/usefulFunctions';
import { AvatarWrapper, BlockInformationText, StyledColumn, StyledSection, TitleText } from './styles';

const { updateAsyncModalLoading, closeAsyncModal, openAsyncModal, openDeleteOrBlockUserModal } = modalEvents;
const { removeRoleByUserId, addRoleByUserId, changeAbilityByUserId } = usersEvents;
const { getUserExternalNetworks } = usersEffects;
const { userExternalNetworks } = usersStores;

interface Props extends BULLZ.AdminGetUserCommon {
    showArrowBack?: boolean;
}

export const UserDescription = ({
    username,
    gender,
    dateOfBirth,
    id = '',
    facilitatorId,
    freeStakingRemaining,
    locale,
    location,
    profileImageUrl,
    roles,
    utcCreated,
    utcUpdated,
    /*@ts-ignore - Removing from API Model*/
    email,
    /*@ts-ignore - Removing from API Model*/
    mobileNumber,
    isAccountVerified,
    utcLastAuthentication,
    isDisabled,
    isTrusted,
    showArrowBack = true
}: Props) => {
    const { access } = useStore(userStores.auth);
    const { items } = useStore(userExternalNetworks);
    const age = getAge(dateOfBirth);
    const isMobile = useMediaQuery({ query: '(max-width: 640px)' });
    const isLaptop = useMediaQuery({ query: `(max-width: ${lg_1})` });
    const blockWidth = isMobile ? '100%' : isLaptop ? propertyBlockLaptopWidth : propertyBlockDesktopWidth;
    const buttonWidth = isLaptop ? cardButtonWidth : undefined;
    const buttonHeight = isLaptop ? cardButtonHeight : undefined;
    const isAgentRoleAssigned = roles?.includes(agentRole);

    const networksData = getNetworksData(items);

    const assignedRoles = useMemo(
        () =>
            access === Roles.SuperAdministrator
                ? assignedUserRolesForSuperAdmin.filter(i => !roles?.includes(i))
                : assignedUserRoles.filter(i => !roles?.includes(i)),
        [roles, access]
    );

    // const assignedRolesForSuperAdmin = useMemo(() => assignedUserRolesForSuperAdmin.filter(i => !roles?.includes(i)), [
    //     roles
    // ]);

    const removeRoleOkHandler = async (subject: SubjectType) => {
        const sub = subject.toString();
        try {
            updateAsyncModalLoading();
            const newRoles = roles?.filter(i => i !== sub);
            await API.adminUsers.updateUserById({
                id: id,
                roles: newRoles
            });
            updateAsyncModalLoading();

            closeAsyncModal();
            message.success(parseRemoveRoleSuccessMessage(sub, username || ''));
            removeRoleByUserId({ id, role: sub });
        } catch {
            updateAsyncModalLoading();
            closeAsyncModal();
            message.error(asyncError);
        }
    };

    const assignRoleOkHandler = async (subject: SubjectType) => {
        const sub = subject.toString();
        try {
            updateAsyncModalLoading();
            const newRoles = roles ? [...roles, sub] : [sub];
            await API.adminUsers.updateUserById({
                id: id,
                roles: newRoles
            });
            updateAsyncModalLoading();

            closeAsyncModal();
            message.success(parseAssignSuccessMessage(sub, username || ''));
            addRoleByUserId({ id, role: sub });
        } catch {
            updateAsyncModalLoading();
            closeAsyncModal();
            message.error(asyncError);
        }
    };
    const verifyOkHandler = async (subject: SubjectType) => {
        const sub = !!subject;
        try {
            updateAsyncModalLoading();
            const {
                facilitatorId,
                firstName,
                lastName,
                country,
                gender,
                dateOfBirth,
                primaryLanguage,
                locale,
                freeStakingRemaining,
                isFreeStakingEligible,
                roles,
                isDisabled
            } = await API.adminUsers.getUserById({ id });
            await API.adminUsers.updateUserById({
                id,
                facilitatorId,
                firstName,
                lastName,
                country,
                gender,
                dateOfBirth,
                primaryLanguage,
                locale,
                freeStakingRemaining,
                isFreeStakingEligible,
                roles,
                isDisabled,
                isTrusted: sub
            });
            updateAsyncModalLoading();

            closeAsyncModal();
            message.success(parseVerifySuccessMessage(sub, username || ''));
            changeAbilityByUserId({ id, isTrusted: sub });
        } catch {
            updateAsyncModalLoading();
            closeAsyncModal();
            message.error(asyncError);
        }
    };

    const enableOkHandler = async (subject: SubjectType) => {
        const sub = !subject;
        try {
            updateAsyncModalLoading();
            await API.adminUsers.enableUsersById({
                users: [id]
            });
            updateAsyncModalLoading();

            closeAsyncModal();
            message.success(parseDisableSuccessMessage(sub, username || ''));
            changeAbilityByUserId({ id, isDisabled: sub });
        } catch {
            updateAsyncModalLoading();
            closeAsyncModal();
            message.error(asyncError);
        }
    };

    const callRemoveRoleModal = (role: SubjectType) =>
        openAsyncModal({
            visible: true,
            title: removeRoleTitle,
            content: parseRemoveRoleDescription(username || '', role.toString()),
            subject: role,
            onOk: removeRoleOkHandler
        });

    const callAssignModal = (role: SubjectType) =>
        openAsyncModal({
            visible: true,
            title: assigningTitle,
            content: parseAssignRoleDescription(username || '', role.toString()),
            subject: role,
            onOk: assignRoleOkHandler
        });
    const callVerifyModal = (userVerified: SubjectType) =>
        openAsyncModal({
            visible: true,
            title: parseVerifyTitle(!!userVerified),
            content: parseVerifyDescription(!!userVerified, username || ''),
            subject: userVerified,
            onOk: verifyOkHandler
        });

    const toggleAgentRole = () => {
        infoModal.openModal({
            title: isAgentRoleAssigned
                ? `Are you sure you want to remove ${username} as an Agent?`
                : `Are you sure you want to make ${username} an Agent?`,
            buttonText: 'OK',
            cancelText: 'Cancel',
            onClick: () => {
                if (isAgentRoleAssigned) {
                    agentEffects.deleteAgentRoleFx(id);
                } else {
                    agentEffects.addAgentRoleFx({
                        userId: id,
                        earningFee: 0.1
                    });
                }
            }
        });
    };

    const deleteOkHandler = async (id: SubjectType) => {
        if (typeof id !== 'string') return;

        await usersEffects.loadSingleItemById(id);
    };

    const callDeleteUserModal = (userId: string) => {
        openDeleteOrBlockUserModal({
            userId,
            username: username || '',
            action: 'delete',
            reasonsList: deleteReasonsList,
            onOk: deleteOkHandler
        });
    };

    const callEnableModal = (id: SubjectType) =>
        openAsyncModal({
            visible: true,
            title: parseDisableTitle(false),
            content: parseDisableDescription(false, username || ''),
            subject: id,
            onOk: enableOkHandler
        });

    const callBlockUserModal = (userId: string) => {
        openDeleteOrBlockUserModal({
            userId,
            username: username || '',
            action: 'block',
            reasonsList: deleteReasonsList,
            onOk: deleteOkHandler
        });
    };

    const onBack = () => history.goBack();

    const localeOkHandler = async (subject: SubjectType) => {
        if (typeof subject !== 'string') return;

        try {
            updateAsyncModalLoading();
            await API.adminUsers.updateUserById({
                id: id,
                locale: subject
            });
            await usersEffects.loadSingleItemById(id);
            updateAsyncModalLoading();

            closeAsyncModal();
            message.success('Locale have been updated!');
            changeAbilityByUserId({ id, locale: subject });
        } catch {
            await usersEffects.loadSingleItemById(id);
            updateAsyncModalLoading();
            closeAsyncModal();
            message.error(asyncError);
        }
    };

    const onLocaleSave = (locale: string) => {
        openAsyncModal({
            visible: true,
            title: `Are you sure you want to change ${username} locale?`,
            content: '',
            subject: locale,
            onOk: localeOkHandler,
            modalHeight: '220px'
        });
    };

    const onFindInWOM = () => {
        window.open(getRedirectUrlToWom(id));
    };

    useEffect(() => {
        getUserExternalNetworks({ userId: id });
    }, [id]);

    return (
        <ContentWrapper backgroundColor={grey29} padding="16px 32px" width="100%">
            <Section justifyBetween marginBottom="28px">
                <Row alignCenter marginBottom={secondaryMargin}>
                    {showArrowBack && (
                        <Column marginRight="24px">
                            <CustomImg
                                pointer
                                height={backImgDiameter}
                                src={backArrowImg}
                                width={backImgDiameter}
                                onClick={onBack}
                            />
                        </Column>
                    )}

                    <TitleText>User info</TitleText>
                </Row>
                <Row alignCenter>
                    <SuperAdministratorLayout>
                        <MarginWrapper marginRight={primaryMargin}>
                            <CardButton
                                backgroundHover={hoverGrey2}
                                color={blue}
                                height={buttonHeight}
                                textHover={white}
                                width={buttonWidth}
                                onClick={onFindInWOM}
                            >
                                Find in WOM admin system
                            </CardButton>
                        </MarginWrapper>
                    </SuperAdministratorLayout>
                    {/* <AdministratorLayout>
                        <Column marginRight={primaryMargin}>
                            <GenerateReportButton onClick={() => userReportModal.openModal({ id })} />
                        </Column>
                    </AdministratorLayout> */}
                    {isDisabled ? (
                        <Row alignCenter>
                            <MarginWrapper marginRight={primaryMargin}>
                                <BlockInformationText>This User is Blocked or Deleted</BlockInformationText>
                            </MarginWrapper>
                            <CardButton
                                height={buttonHeight}
                                marginRight={primaryMargin}
                                type="danger"
                                width={buttonWidth}
                                onClick={() => {
                                    callEnableModal(id);
                                }}
                            >
                                Unblock
                            </CardButton>
                        </Row>
                    ) : (
                        <AdministratorLayout>
                            <Column marginRight={primaryMargin}>
                                <CardButton
                                    backgroundHover={hoverGrey2}
                                    color={blue}
                                    height={buttonHeight}
                                    textHover={white}
                                    width={buttonWidth}
                                    onClick={() => callVerifyModal(!isTrusted)}
                                >
                                    {isTrusted ? 'Unverify' : 'Verify'}
                                </CardButton>
                            </Column>
                            <Column marginRight={primaryMargin}>
                                <RolesPopover
                                    disabled={!assignedRoles.length}
                                    setSubject={callAssignModal}
                                    subjects={assignedRoles}
                                    title={assignRoleTitle}
                                    type="down"
                                >
                                    <CardButton
                                        backgroundHover={hoverGrey2}
                                        color={blue}
                                        disabled={!assignedRoles.length}
                                        height={buttonHeight}
                                        textHover={white}
                                        width={buttonWidth}
                                    >
                                        Assign Role
                                    </CardButton>
                                </RolesPopover>
                            </Column>
                        </AdministratorLayout>
                    )}
                    <SuperAdministratorLayout>
                        <CardButton
                            backgroundHover={hoverGrey2}
                            color={blue}
                            height={buttonHeight}
                            marginRight={primaryMargin}
                            textHover={white}
                            width={buttonWidth}
                            onClick={toggleAgentRole}
                        >
                            {isAgentRoleAssigned ? 'Remove Agent' : 'Make Agent'}
                        </CardButton>
                    </SuperAdministratorLayout>
                    {!isDisabled && (
                        <AdministratorLayout>
                            <CardButton
                                background={errorColor}
                                backgroundHover={hoverGrey2}
                                color={white}
                                height={buttonHeight}
                                marginRight={primaryMargin}
                                type="danger"
                                width={buttonWidth}
                                onClick={() => callBlockUserModal(id)}
                            >
                                Block
                            </CardButton>
                            <CardButton
                                background={errorColor}
                                backgroundHover={hoverGrey2}
                                color={white}
                                height={buttonHeight}
                                type="danger"
                                width={buttonWidth}
                                onClick={() => callDeleteUserModal(id)}
                            >
                                Delete
                            </CardButton>
                        </AdministratorLayout>
                    )}
                </Row>
            </Section>
            <StyledSection>
                <AvatarWrapper>
                    <AvatarImg
                        isAccountVerified={isAccountVerified}
                        isDisabled={isDisabled}
                        isTrusted={isTrusted}
                        src={profileImageUrl || ''}
                    />
                </AvatarWrapper>

                <StyledColumn>
                    <Section marginBottom={primaryMargin}>
                        <ContentText fontSize={subtitleFontSize}>About</ContentText>
                    </Section>

                    <Section>
                        <PropertyBlock
                            copiable
                            notEllipsisSubtitle
                            titleUppercase
                            backgroundColor={grey27}
                            customCopyIcon={whiteCopyIcon}
                            marginBottom={secondaryMargin}
                            marginRight={primaryMargin}
                            subtitle={username || ''}
                            success={copyUsernameMessage}
                            title="User Name"
                            width={blockWidth}
                        />

                        <SuperAdministratorLayout>
                            <PropertyBlock
                                copiable
                                notEllipsisSubtitle
                                titleUppercase
                                backgroundColor={grey27}
                                customCopyIcon={whiteCopyIcon}
                                marginBottom={secondaryMargin}
                                marginRight={primaryMargin}
                                notVerified={!isAccountVerified}
                                subtitle={email}
                                success={copyEmailMessage}
                                title="Email Address"
                                width={blockWidth}
                            />
                        </SuperAdministratorLayout>

                        <PropertyBlock
                            notEllipsisSubtitle
                            titleUppercase
                            backgroundColor={grey27}
                            customCopyIcon={whiteCopyIcon}
                            marginBottom={secondaryMargin}
                            marginRight={primaryMargin}
                            subtitle={age}
                            title="Age"
                            width={blockWidth}
                        />

                        <PropertyBlock
                            notEllipsisSubtitle
                            titleUppercase
                            backgroundColor={grey27}
                            customCopyIcon={whiteCopyIcon}
                            marginBottom={secondaryMargin}
                            marginRight={primaryMargin}
                            subtitle={userGender[gender || 0]}
                            title="Gender"
                            width={blockWidth}
                        />

                        <SuperAdministratorLayout>
                            <PropertyBlock
                                copiable
                                notEllipsisSubtitle
                                titleUppercase
                                backgroundColor={grey27}
                                customCopyIcon={whiteCopyIcon}
                                marginBottom={secondaryMargin}
                                marginRight={primaryMargin}
                                subtitle={mobileNumber}
                                success={copyPhoneMessage}
                                title="Phone"
                                width={blockWidth}
                            />
                        </SuperAdministratorLayout>

                        <PropertyBlock
                            titleUppercase
                            backgroundColor={grey27}
                            marginBottom={secondaryMargin}
                            marginRight={primaryMargin}
                            subtitle={freeStakingRemaining?.toString()}
                            title="Free stakes remaining"
                            width={blockWidth}
                        />
                    </Section>

                    <Section marginBottom={primaryMargin}>
                        <ContentText fontSize={subtitleFontSize}>IDs</ContentText>
                    </Section>

                    <Section>
                        <PropertyBlock
                            copiable
                            notEllipsisSubtitle
                            titleUppercase
                            backgroundColor={grey27}
                            customCopyIcon={whiteCopyIcon}
                            linkRoute={usersLink}
                            marginBottom={secondaryMargin}
                            marginRight={primaryMargin}
                            subtitle={id}
                            success={copyUserIdMessage}
                            title="Id"
                            width={blockWidth}
                        />

                        <PropertyBlock
                            copiable
                            titleUppercase
                            backgroundColor={grey27}
                            customCopyIcon={whiteCopyIcon}
                            marginBottom={secondaryMargin}
                            marginRight={primaryMargin}
                            subtitle={facilitatorId}
                            success={copyFacilitatorIdMessage}
                            title="Facilitator ID"
                            width={blockWidth}
                        />
                    </Section>

                    <Section marginBottom={primaryMargin}>
                        <ContentText fontSize={subtitleFontSize}>Region</ContentText>
                    </Section>

                    <Section>
                        <ChangeablePropertyBlock
                            titleUppercase
                            backgroundColor={grey27}
                            disabled={access !== Roles.Administrator && access !== Roles.SuperAdministrator}
                            marginBottom={secondaryMargin}
                            marginRight={primaryMargin}
                            searchPlaceholder="Input a locale"
                            selector={localeSelector.nestedSelectors?.map(({ selectorName }) => selectorName || '')}
                            subtitle={locale || ''}
                            title="Locale"
                            width={blockWidth}
                            onSave={onLocaleSave}
                        />

                        <PropertyBlock
                            notEllipsisSubtitle
                            titleUppercase
                            backgroundColor={grey27}
                            marginBottom={secondaryMargin}
                            marginRight={primaryMargin}
                            subtitle={location?.countryName || ''}
                            title="Country"
                            width={blockWidth}
                        />

                        <PropertyBlock
                            notEllipsisSubtitle
                            titleUppercase
                            backgroundColor={grey27}
                            marginBottom={secondaryMargin}
                            marginRight={primaryMargin}
                            subtitle={location?.area?.region || ''}
                            title="Region"
                            width={blockWidth}
                        />
                    </Section>

                    <Section marginBottom={primaryMargin}>
                        <ContentText fontSize={subtitleFontSize}>Account</ContentText>
                    </Section>

                    <Section>
                        <PropertyBlock
                            isDate
                            titleUppercase
                            backgroundColor={grey27}
                            marginBottom={secondaryMargin}
                            marginRight={primaryMargin}
                            subtitle={utcCreated}
                            title="Created account"
                            width={blockWidth}
                        />

                        <PropertyBlock
                            isDate
                            titleUppercase
                            backgroundColor={grey27}
                            marginBottom={secondaryMargin}
                            marginRight={primaryMargin}
                            subtitle={utcUpdated}
                            title="Updated account"
                            width={blockWidth}
                        />

                        <PropertyBlock
                            isDate
                            titleUppercase
                            backgroundColor={grey27}
                            marginBottom={secondaryMargin}
                            marginRight={primaryMargin}
                            subtitle={utcLastAuthentication}
                            title="Last logged in"
                            width={blockWidth}
                        />
                    </Section>

                    <Section marginBottom={primaryMargin}>
                        <ContentText fontSize={subtitleFontSize}>Social Accounts</ContentText>
                    </Section>

                    <Section>
                        {networksData?.map(({ name, linked, engagement, link, id }) => (
                            <PropertyBlock
                                key={name}
                                notEllipsisSubtitle
                                titleUppercase
                                backgroundColor={grey27}
                                externalLink={link}
                                linkId={id}
                                marginBottom={secondaryMargin}
                                marginRight={primaryMargin}
                                notLinked={!linked}
                                subtitle={engagement}
                                title={name}
                                width={blockWidth}
                            />
                        ))}
                    </Section>

                    <AgentSection isAgentRoleAssigned={isAgentRoleAssigned} userId={id} />

                    <Section alignCenter marginTop={primaryMargin}>
                        {roles?.length
                            ? roles.map((item: string) => (
                                  <MarginWrapper key={item} marginBottom={primaryMargin} marginRight={primaryMargin}>
                                      <ClosableTag
                                          subject={item}
                                          untouchable={
                                              defaultUserRoles.includes(item) ||
                                              access > Roles.Administrator ||
                                              item === agentMemberRole
                                          }
                                          onClose={callRemoveRoleModal}
                                      />
                                  </MarginWrapper>
                              ))
                            : rolesAbsentMessage}
                    </Section>
                </StyledColumn>
            </StyledSection>
        </ContentWrapper>
    );
};

interface UserVideosProps {
    videos?: BULLZ.AdminGetVideoResponse[];
    isUserVideos?: boolean;
}

export const UserVideos = ({ videos = [], isUserVideos }: UserVideosProps) => (
    <>
        <Section>
            {videos.length ? (
                videos.map(item => <VideoCard key={item.id} isUserVideos={isUserVideos} {...item} />)
            ) : (
                <Empty title={videosNotFoundMessage} />
            )}
        </Section>
    </>
);
