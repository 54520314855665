import { Row } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import styled from 'styled-components';

export const TopWrapper = styled(Row)`
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 14px;
    padding-right: 45px;
`;

export const SearchWrapper = styled(Row)`
    width: 394px;
    align-items: center;
`;
