import { Section } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { grey27, grey32, tableBorderColor } from 'constants/styles/colors';
import { flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';

export const CellContentWrapper = styled(Section)`
    ${flexCenter};
    padding: 10px 20px;
    white-space: nowrap;
`;

export const TableWrapper = styled.div`
    margin-bottom: 60px;
    width: 100%;
    padding-bottom: 6px;
    border: 1px solid ${tableBorderColor};
    border-radius: 6px;
    overflow-x: auto;

    ::-webkit-scrollbar {
        height: 6px;
        width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: ${grey27};
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: ${grey32};
    }
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

export const TableRow = styled.tr`
    width: 100%;

    &:not(:last-child) {
        border-bottom: 1px solid ${tableBorderColor};
    }
`;

export const TableHeaderCell = styled.th`
    height: 34px;
`;

export const TableDataCell = styled.td`
    height: 34px;
`;
