import { Button as AntButton, Form } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { tailLayout } from 'components/formComponents/FormButton/constants';
import React, { FC } from 'react';

const { wrapperCol } = tailLayout;

interface Props extends ButtonProps {
    hasOffset?: boolean;
}

export const FormButton: FC<Props> = ({
    hasOffset = true,
    type = 'primary',
    htmlType = 'submit',
    disabled = false,
    children,
    ...rest
}) => (
    <Form.Item
        wrapperCol={{
            ...wrapperCol,
            offset: hasOffset ? wrapperCol.offset : undefined
        }}
    >
        <AntButton disabled={disabled} htmlType={htmlType} type={type} {...rest}>
            {children}
        </AntButton>
    </Form.Item>
);
