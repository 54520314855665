import { useMediaQuery } from '@material-ui/core';
import yeayLogo from 'assets/bullz_logo_white.svg';
import history from 'browserHistory';
import { CustomImg } from 'componentsNewDesign/common/imgComponents/CustomImg';
import { AbsoluteWrapper } from 'componentsNewDesign/wrappers/grid/AbsoluteWrapper';
import { Column } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { marketingToolsLink } from 'constants/routes';
import { sm } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import { useNonScrolledBackground } from 'hooks/nonScrolledBackground';
import React from 'react';
import { promotionForm } from 'stores/forms/promotionForm';
import { promotionCreatedCongratsModal } from 'stores/initialize/initialize.modal.store';
import { Modal, ModalContentWrapper, StyledButton, StyledSpan, TextWrapper, Title, Triangle, Wrapper } from './styles';

const { modal, closeModal } = promotionCreatedCongratsModal;

export const CongratsModal = () => {
    const [visible, { promotionId }] = useStore(modal);
    const isMobile = useMediaQuery(`(max-width: ${sm})`);

    const onDoneClick = () => {
        closeModal();
        history.push(marketingToolsLink);
        promotionForm.resetValues();
    };

    useNonScrolledBackground(visible);

    return (
        <Wrapper visible={visible}>
            <Modal>
                <AbsoluteWrapper right="0px" top="0px" width="100%" zIndex="5">
                    <Column alignCenter marginTop={isMobile ? '30px' : '60px'}>
                        <CustomImg src={yeayLogo} width={isMobile ? '104px' : '180px'} />
                    </Column>
                </AbsoluteWrapper>
                <Triangle />
                <ModalContentWrapper>
                    <Title>Congrats!</Title>

                    <TextWrapper>
                        <StyledSpan>You have successfully started the&nbsp;promotion</StyledSpan>
                        <StyledSpan>(id {promotionId})</StyledSpan>
                    </TextWrapper>

                    <StyledButton onClick={onDoneClick}>Done</StyledButton>
                </ModalContentWrapper>
            </Modal>
        </Wrapper>
    );
};
