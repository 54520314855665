import { grey3 } from 'constants/styles/colors';

export const propertyBlockBackground = grey3;
export const propertyBlockMarginBottom = '4px';
export const propertyBlockMarginBottomMobile = '0';

export const incorrectText = 'Incorrect Format';

// export const titleFontSize = '12px';
// export const subtitleFontSize = '14px';

// export const titleColor = grey13;
// export const subTitleColor = black;
