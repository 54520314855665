import { useMediaQuery } from '@material-ui/core';
import productSrc from 'assets/img/product.png';
import { ManagerLayout } from 'components/layouts/RolesLayouts';
import { CardButton } from 'componentsNewDesign/common/buttons/CardButton';
import { CustomImg } from 'componentsNewDesign/common/imgComponents/CustomImg';
import { HashtagsInput } from 'componentsNewDesign/common/inputs/HashtagsInput';
import { Span } from 'componentsNewDesign/common/typography/Span';
import { PropertyBlock } from 'componentsNewDesign/layouts/blocks/PropertyBlock';
import { copyTopicLinkMessage } from 'componentsNewDesign/layouts/cards/ProductCard/constants';
import {
    productCardWidth,
    propertyBlockHorizontalPadding,
    propertyBlockMobileWidth,
    propertyBlockWidth
} from 'componentsNewDesign/layouts/descriptionLayouts/ProductDescription/constants';
import { EditProductPopover } from 'componentsNewDesign/modals/popovers/products/EditProductPopover';
import { UploadProductImgPopover } from 'componentsNewDesign/modals/popovers/products/UploadProductImgPopover';
import { ContentWrapper } from 'componentsNewDesign/wrappers/ContentWrapper';
import { DescriptionWrapper } from 'componentsNewDesign/wrappers/DescriptionWrapper';
import { Column, FlexGrow, Row, Section } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { grey27, grey29, grey7, hoverGrey2 } from 'constants/styles/colors';
import { descriptionPadding, filterMargin, filterMarginMobile, xs } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import React from 'react';
import { loadingStores } from 'stores/loading';
import { modalEffects } from 'stores/modals/asyncModal';
import { productsEffects, productsEvents } from 'stores/products/products';
import { ImageWrapper } from './styles';

export interface ProductDescriptionProps extends BULLZ.TopicResponse, BULLZ.AffiliateLinkEntryResponse {
    primaryReferenceCount?: number;
}

export const ProductDescription = ({
    id = '',
    name = '',
    link = '',
    tags = [],
    primaryReferenceCount = 0,
    imageUrl
}: ProductDescriptionProps) => {
    const loading = useStore(loadingStores.loading);
    const productLoading = useStore(modalEffects.editProductInfo.pending);

    const changeInfoCallBack = (fields: BULLZ.UpdateTopicRequest) => productsEvents.updateItemById({ id, ...fields });

    const onConfirm = async (hashtags: string[]) => {
        try {
            await productsEffects.loadEditInfoItemById(id);
            await modalEffects.editProductInfo({ onChange: changeInfoCallBack, tags: hashtags, id: id });
            await productsEffects.loadEditInfoItemById(id);
        } catch {}
    };

    const isMobile = useMediaQuery(`(max-width: ${xs})`);

    return !isMobile ? (
        <DescriptionWrapper backgroundColor={grey29} marginBottom={filterMargin} padding="28px 24px 23px" width="100%">
            <ContentWrapper borderRadius="8px" width="100%">
                <Section alignEnd>
                    <ImageWrapper
                        alignCenter
                        justifyCenter
                        marginBottom={descriptionPadding}
                        marginRight={descriptionPadding}
                    >
                        <CustomImg height="auto" src={imageUrl || productSrc} width={imageUrl ? '80%' : '32px'} />
                    </ImageWrapper>

                    <Column marginBottom={descriptionPadding} marginRight={descriptionPadding}>
                        <Row alignCenter marginBottom={descriptionPadding}>
                            <Span fontSize="16px" fontWeight="500" lineHeight="19px">
                                {name}
                            </Span>
                        </Row>
                        <HashtagsInput
                            hashTags={tags || undefined}
                            loading={productLoading || loading}
                            type="product"
                            onConfirm={onConfirm}
                        />
                    </Column>

                    <Column>
                        <Section alignCenter justifyBetween height="100%" marginBottom="7px">
                            <PropertyBlock
                                copiable
                                isLink
                                backgroundColor={grey27}
                                horizontalPadding={propertyBlockHorizontalPadding}
                                marginRight="8px"
                                subtitle={link || ''}
                                success={copyTopicLinkMessage}
                                title="Topic link"
                                width={propertyBlockWidth}
                            />

                            <PropertyBlock
                                backgroundColor={grey27}
                                horizontalPadding={propertyBlockHorizontalPadding}
                                marginRight="8px"
                                subtitle={primaryReferenceCount.toString()}
                                title="Referenced count"
                                width={propertyBlockWidth}
                            />

                            <PropertyBlock
                                notEllipsisSubtitle
                                backgroundColor={grey27}
                                horizontalPadding={propertyBlockHorizontalPadding}
                                minWidth={propertyBlockWidth}
                                subtitle={tags?.[0] || undefined}
                                title="Category"
                                width="fit-content"
                            />
                        </Section>
                        <ManagerLayout>
                            <Section alignCenter height="100%">
                                <Row>
                                    <EditProductPopover id={id} name={name || ''} type="down">
                                        <CardButton
                                            background={grey27}
                                            backgroundHover={hoverGrey2}
                                            color={grey7}
                                            marginRight={descriptionPadding}
                                        >
                                            Edit Link
                                        </CardButton>
                                    </EditProductPopover>
                                </Row>

                                <Row>
                                    <UploadProductImgPopover id={id} type="down">
                                        <CardButton
                                            background={grey27}
                                            backgroundHover={hoverGrey2}
                                            color={grey7}
                                            marginRight={descriptionPadding}
                                        >
                                            Upload Image
                                        </CardButton>
                                    </UploadProductImgPopover>
                                </Row>
                            </Section>
                        </ManagerLayout>
                    </Column>
                </Section>
            </ContentWrapper>
        </DescriptionWrapper>
    ) : (
        <DescriptionWrapper
            backgroundColor={grey29}
            marginBottom={filterMarginMobile}
            marginRight="0"
            padding="24px 8px 18px"
            width="100%"
        >
            <ContentWrapper borderRadius="8px" width="100%">
                <FlexGrow noWrap>
                    <Section marginBottom="8px" marginTop="8px">
                        <Span fontSize="12px" fontWeight="500" lineHeight="12px">
                            {name}
                        </Span>
                    </Section>
                    <Section marginBottom={descriptionPadding}>
                        <HashtagsInput
                            hashTags={tags || undefined}
                            loading={productLoading || loading}
                            type="product"
                            onConfirm={onConfirm}
                        />
                    </Section>

                    <Section justifyBetween noWrap height="100%" marginBottom="4px">
                        <PropertyBlock
                            copiable
                            isLink
                            backgroundColor={grey27}
                            horizontalPadding={propertyBlockHorizontalPadding}
                            subtitle={link || ''}
                            success={copyTopicLinkMessage}
                            title="Topic link"
                            width={propertyBlockMobileWidth}
                        />
                        <PropertyBlock
                            backgroundColor={grey27}
                            horizontalPadding={propertyBlockHorizontalPadding}
                            subtitle={primaryReferenceCount.toString()}
                            title="Referenced count"
                            width={propertyBlockMobileWidth}
                        />
                        <PropertyBlock
                            notEllipsisSubtitle
                            backgroundColor={grey27}
                            horizontalPadding={propertyBlockHorizontalPadding}
                            marginBottom="0"
                            subtitle={tags?.[0] || undefined}
                            title="Category"
                            width={propertyBlockMobileWidth}
                        />
                    </Section>
                    <ManagerLayout>
                        <Section alignCenter justifyCenter height="100%">
                            <Row marginRight="8px" width={productCardWidth}>
                                <EditProductPopover id={id} name={name || ''} type="down">
                                    <CardButton
                                        background={grey27}
                                        backgroundHover={hoverGrey2}
                                        color={grey7}
                                        marginRight={descriptionPadding}
                                    >
                                        Edit Link
                                    </CardButton>
                                </EditProductPopover>
                            </Row>

                            <Row width={productCardWidth}>
                                <UploadProductImgPopover id={id} type="down">
                                    <CardButton
                                        background={grey27}
                                        backgroundHover={hoverGrey2}
                                        color={grey7}
                                        marginRight={descriptionPadding}
                                    >
                                        Upload Image
                                    </CardButton>
                                </UploadProductImgPopover>
                            </Row>
                        </Section>
                    </ManagerLayout>
                </FlexGrow>
            </ContentWrapper>
        </DescriptionWrapper>
    );
};
