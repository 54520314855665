import { sectionBorderRadius } from 'componentsNewDesign/common/dropdowns/SectionDropdown/constants';
import { grey29, white } from 'constants/styles/colors';
import { disableDefaultButtonStyleMixin } from 'constants/styles/mixins';
import styled from 'styled-components';

export const DropdownSectionWrapper = styled.div`
    width: 100%;
    height: fit-content;
    background-color: ${grey29};

    &:first-child {
        border-top-left-radius: ${sectionBorderRadius};
        border-top-right-radius: ${sectionBorderRadius};
    }
    &:last-child {
        border-bottom-left-radius: ${sectionBorderRadius};
        border-bottom-right-radius: ${sectionBorderRadius};
    }
`;

export const DropdownSectionButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    border-radius: inherit;
    border-top-left-radius: inherit;
    width: 100%;
    height: auto;
    color: ${white};
    background-color: ${grey29};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`;

export const DropdownSectionContent = styled.div`
    width: 100%;
    height: fit-content;
    padding-bottom: 14px;
    background-color: ${grey29};
    border-top-left-radius: ${sectionBorderRadius};
    border-top-right-radius: ${sectionBorderRadius};
    border-bottom-left-radius: ${sectionBorderRadius};
    border-bottom-right-radius: ${sectionBorderRadius};
    margin-bottom: 10px;
`;

export const TotalBadgeWrapper = styled.div`
    margin-bottom: 12px;
    padding-left: 20px;
`;
