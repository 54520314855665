export enum ExternalNetwork {
    Instagram = 'Instagram',
    Twitter = 'Twitter',
    YouTube = 'YouTube'
}

export type NetworkType = ExternalNetwork.Instagram | ExternalNetwork.Twitter | ExternalNetwork.YouTube;

export const externalNetworkId = {
    [ExternalNetwork.Twitter]: '42cf1877f4270da2aff8f23a',
    [ExternalNetwork.YouTube]: 'bb11c89f527e9065dca9e7bc',
    [ExternalNetwork.Instagram]: '9bba48d0037be8100849c751'
};
