import { SimpleButton } from 'componentsNewDesign/common/buttons/SimpleButton';
import { BooleanCheckbox } from 'componentsNewDesign/common/inputs/Checkbox';
import { Row } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { MarginWrapper } from 'componentsNewDesign/wrappers/grid/MarginWrapper';
import { errorColor, white } from 'constants/styles/colors';
import React, { Dispatch, FC, SetStateAction } from 'react';

interface Props {
    checked: boolean;
    disabledButton: boolean;
    isUpdate: boolean;
    onUpdateLimit: () => void;
    onCreateLimit: () => void;
    resetValues: () => void;
    setChecked: Dispatch<SetStateAction<boolean>>;
}

export const Bottom: FC<Props> = ({
    checked,
    disabledButton,
    isUpdate,
    onUpdateLimit,
    onCreateLimit,
    resetValues,
    setChecked
}) => (
    <>
        <MarginWrapper marginBottom="16px">
            <BooleanCheckbox
                showName
                defaultChecked={checked}
                name={`Are you sure you want to ${isUpdate ? 'update' : 'create new'} limit?`}
                onChange={setChecked}
            />
        </MarginWrapper>
        <Row>
            <SimpleButton
                background={white}
                disabled={disabledButton}
                fontSize="12px"
                height="30px"
                width="81px"
                onClick={isUpdate ? onUpdateLimit : onCreateLimit}
            >
                {isUpdate ? 'Update' : 'Create'} Limit
            </SimpleButton>
            <SimpleButton
                background={errorColor}
                color={white}
                fontSize="12px"
                height="30px"
                marginLeft="15px"
                width="53px"
                onClick={resetValues}
            >
                Cancel
            </SimpleButton>
        </Row>
    </>
);
