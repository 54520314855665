import { power } from 'componentsNewDesign/common/inputs/SocialNetworksFilter/constants';

export function formatStringRangeValue(rangeValue?: string) {
    if (!rangeValue) return 0;

    let letter = '';
    let number = 0;
    let result = 0;

    const includesLetter = Object.keys(power).some(key => {
        letter = key;
        return rangeValue.includes(key);
    });

    if (includesLetter) {
        number = Number(rangeValue.slice(0, -1));

        switch (letter) {
            case 'b':
                result = Math.round(number * Math.pow(10, power.b));
                break;
            case 'm':
                result = Math.round(number * Math.pow(10, power.m));
                break;
            case 'k':
                result = Math.round(number * Math.pow(10, power.k));
                break;
        }
    } else {
        result = Number(rangeValue);
    }

    return result;
}
