import { HorizontalLine } from 'componentsNewDesign/common/dividers/HorizontalLine';
import { ContentText } from 'componentsNewDesign/common/typography/ContentText/styles';
import { headerHeight, headerPaddingTop } from 'componentsNewDesign/grid/Header/constants';
import { Section } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { blue2, grey19, grey28, white } from 'constants/styles/colors';
import { flexCenter } from 'constants/styles/mixins';
import { filterMargin, sideBarWidth } from 'constants/styles/sizes';
import styled from 'styled-components';

export const HeaderWrapper = styled.div`
    /* position: fixed; */
    position: absolute;
    top: 0;
    right: 0;
    height: ${headerHeight};
    display: flex;
    justify-content: space-between;
    // padding-left: calc(${sideBarWidth} + ${filterMargin});
    padding-top: ${headerPaddingTop};
    background-color: transparent;
    z-index: 99;
`;

export const Label = styled(ContentText)`
    color: ${grey19};
    margin-bottom: 7px;
`;

export const UpdatesCounter = styled.div`
    font-size: 9px;
    line-height: 11px;
    height: 13px;
    width: 15px;
    color: ${white};
    border-radius: 50%;
    background-color: ${blue2};
    padding-bottom: 1px;
    ${flexCenter};
`;

export const CurrentVersionData = styled(ContentText)`
    color: ${grey19};
    font-size: 11px;
    line-height: 14px;
    padding: 7px 10px;
`;

export const StyledHorizontalLine = styled(HorizontalLine)`
    margin-top: 7px;
    margin-bottom: 14px;
    background-color: #dbdada;
`;

export const VersionSpan = styled.span`
    position: fixed;
    right: ${filterMargin};
    top: 2px;
    color: #ccc;
    font-size: 11px;
    box-sizing: border-box;
    padding-right: 5px;
    text-align: right;
    color: ${white};
`;

export const MobileHeaderWrapper = styled(Section)`
    justify-content: space-between;
    align-items: center;
    position: fixed;
    height: 80px;
    padding: 0 16px;
    background-color: ${grey28};
    z-index: 100;
`;
