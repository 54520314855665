import { black } from 'constants/styles/colors';
import { flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';

export const BlackWrapper = styled.div`
    ${flexCenter};
    background-color: ${black};
    width: 75px;
    height: 60px;
    border-radius: 8px;
`;
