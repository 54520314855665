import { Column, Row, Section } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { flexCenter } from 'constants/styles/mixins';
import { md_1 } from 'constants/styles/sizes';
import styled from 'styled-components';

export const TitleText = styled.span`
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
`;

export const BlockInformationText = styled.span`
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    color: #ff3333;
    letter-spacing: 0.06em;
    text-transform: uppercase;
`;

export const AvatarWrapper = styled(Row)`
    ${flexCenter};
    width: fit-content;
    margin-right: 24px;

    @media (max-width: ${md_1}) {
        margin-right: 0px;
        margin-bottom: 24px;
    }
`;

export const StyledSection = styled(Section)`
    flex-wrap: nowrap;

    @media (max-width: ${md_1}) {
        flex-direction: column;
        width: 100%;
        ${flexCenter};
    }
`;

export const StyledColumn = styled(Column)`
    flex-wrap: nowrap;
    justify-content: flex-start;

    @media (max-width: ${md_1}) {
        width: 100%;
        ${flexCenter};
    }
`;
