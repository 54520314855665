import { grey30 } from 'constants/styles/colors';
import { lg } from 'constants/styles/sizes';
import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    min-height: 100%;
    background-color: ${grey30};
    border-radius: 24px;
    padding: 30px 24px 18px;
`;

export const Wrapper = styled.div`
    width: 727px;

    @media (max-width: ${lg}) {
        width: 100%;
    }
`;
