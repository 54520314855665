import format from 'date-fns/format';

export const getFormattedDate = (date?: string | null, hasDay = true) => {
    if (!date) return '';
    if (!Date.parse(date)) return '';

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const data = new Date(date);
    const month = months[data.getMonth()];
    const year = data.getFullYear();
    const day = data.getDate();

    if (!hasDay) {
        return `${month} ${year}`;
    }

    return `${day}-${month}-${year}`;
};

export const getFormattedTime = (date?: string | null) => {
    if (!date) return '';
    if (!Date.parse(date)) return '';

    return format(Date.parse(date), 'hh:mm:ss'); // 10:17:12
};
