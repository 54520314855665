import closeIcon from 'assets/close.svg';
import { CardButton } from 'componentsNewDesign/common/buttons/CardButton';
import { HorizontalLine } from 'componentsNewDesign/common/dividers/HorizontalLine';
import { CustomImg } from 'componentsNewDesign/common/imgComponents/CustomImg';
import { BooleanCheckbox } from 'componentsNewDesign/common/inputs/Checkbox';
import { StyledTextInput } from 'componentsNewDesign/common/inputs/StyledTextInput';
import { Span } from 'componentsNewDesign/common/typography/Span';
import { PopoverLayout } from 'componentsNewDesign/modals/popovers/PopoverLayout';
import { ContentWrapper } from 'componentsNewDesign/wrappers/ContentWrapper';
import { AbsoluteWrapper } from 'componentsNewDesign/wrappers/grid/AbsoluteWrapper';
import { Row, Section } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { MarginWrapper } from 'componentsNewDesign/wrappers/grid/MarginWrapper';
import { closeIconDiameter } from 'componentsNewDesign/wrappers/ModalWrapper/constant';
import { blue, errorColor, grey10, grey27 } from 'constants/styles/colors';
import { useModal } from 'hooks/modal';
import React, { FC, useEffect, useState } from 'react';
import { agentEffects } from 'stores/agent';
import { isNumber } from 'utils/isNumber';

interface Props {
    agentId?: string;
}

export const ChangeAgentCommissionPopover: FC<Props> = ({ children, agentId }) => {
    const modal = useModal();
    const [checked, setChecked] = useState(false);
    const [commission, setCommission] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if (commission) {
            if (isNumber(commission)) {
                if (!Number.isInteger(+commission)) {
                    setError('Only whole numbers accepted');
                } else if (+commission > 50) {
                    setError('Maximum is 50%');
                } else if (+commission < 10) {
                    setError('Minimum is 10%');
                } else {
                    setError('');
                }
            } else {
                setError('Please enter a number 0-9');
            }
        }
    }, [commission]);

    const applyClick = () => {
        if (checked && agentId) {
            agentEffects.updateAgentInfoFx({
                data: {
                    earningFee: +commission / 100
                },
                agentId
            });
            modal.close();
            setChecked(false);
            setCommission('');
        }
    };

    return (
        <PopoverLayout
            {...modal}
            minWidth="216px"
            modalChildren={
                <>
                    <Section alignCenter justifyCenter height="42px">
                        <Span color={blue} fontSize="14px" fontWeight="600" lineHeight="16px">
                            Agent Commission
                        </Span>
                    </Section>
                    <AbsoluteWrapper right="15px" top="15px">
                        <CustomImg
                            pointer
                            height={closeIconDiameter}
                            src={closeIcon}
                            width={closeIconDiameter}
                            onClick={() => modal.close()}
                        />
                    </AbsoluteWrapper>
                    <MarginWrapper marginBottom="10px">
                        <HorizontalLine background={grey10} opacity={0.1} />
                    </MarginWrapper>
                    <ContentWrapper paddingLeft="12px" paddingRight="12px">
                        <Section marginBottom="24px">
                            <MarginWrapper marginBottom="8px">
                                <Span fontSize="11px" fontWeight="500" lineHeight="13px">
                                    Agent Commission
                                </Span>
                            </MarginWrapper>
                            <StyledTextInput
                                disableClearButton
                                disableEnterKeyDown
                                defaultValue={commission}
                                placeholder="Enter percentage here"
                                prefix="%"
                                onChange={setCommission}
                            />
                            {error && (
                                <Row marginTop="4px">
                                    <Span color={errorColor} fontSize="11px" fontWeight="500" lineHeight="13px">
                                        {error}
                                    </Span>
                                </Row>
                            )}
                        </Section>
                        <Section>
                            <Section alignCenter justifyCenter noWrap marginBottom="10px">
                                <MarginWrapper marginRight="8px">
                                    <BooleanCheckbox defaultChecked={checked} onChange={setChecked} />
                                </MarginWrapper>
                                <Span fontSize="11px" fontWeight="500" lineHeight="13px">
                                    Are you sure?
                                </Span>
                            </Section>
                            <Section justifyCenter zIndex="31">
                                <CardButton
                                    background={grey27}
                                    disabled={!checked || !!error || !commission}
                                    onClick={applyClick}
                                >
                                    Apply
                                </CardButton>
                            </Section>
                        </Section>
                    </ContentWrapper>
                </>
            }
            type="top"
            visibleChildren={children}
        />
    );
};
