import { useMediaQuery } from '@material-ui/core';
import { ModalButton } from 'componentsNewDesign/common/buttons/ModalButton';
import { ContentText, TitleText } from 'componentsNewDesign/modals/AsyncModal/styles';
import { Column, Row } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { ModalWrapper } from 'componentsNewDesign/wrappers/ModalWrapper';
import { errorColor, grey27 } from 'constants/styles/colors';
import { sm } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import React from 'react';
import { modalEvents, modalStores } from 'stores/modals/asyncModal';

export const AsyncModal = () => {
    const [loading, { visible, subject, title, content, onOk, modalHeight }] = useStore(modalStores.asyncModalStore);
    const isMobile = useMediaQuery(`(max-width: ${sm})`);

    const okHandler = () => onOk && onOk(subject);
    const onClose = () => modalEvents.closeAsyncModal();

    return (
        <ModalWrapper
            height={isMobile ? 'fit-content' : modalHeight || '240px'}
            visible={visible}
            width={isMobile ? '256px' : '700px'}
            onClose={onClose}
        >
            <Column alignCenter width="100%">
                <Row height={title ? 'fit-content' : '24px'} marginBottom="20px">
                    <TitleText>{title}</TitleText>
                </Row>
                <Row marginBottom="38px">
                    <ContentText>{content}</ContentText>
                </Row>
                <Row marginBottom="0">
                    <Column marginRight={isMobile ? '6px' : '16px'}>
                        <ModalButton background={grey27} onClick={okHandler}>
                            {loading ? 'Loading...' : 'Ok'}
                        </ModalButton>
                    </Column>

                    <ModalButton background={errorColor} disabled={loading} onClick={onClose}>
                        Cancel
                    </ModalButton>
                </Row>
            </Column>
        </ModalWrapper>
    );
};
