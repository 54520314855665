import { SimpleButton } from 'componentsNewDesign/common/buttons/SimpleButton';
import { ItemSpan } from 'componentsNewDesign/common/inputs/Select/styles';
import { sideBarZIndex } from 'componentsNewDesign/grid/SideBar/constants';
import { contentWrapperBorderRadius } from 'componentsNewDesign/wrappers/ContentWrapper/constants';
import { Column, Section } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { blue, blue2, errorColor, grey23, grey28 } from 'constants/styles/colors';
import { disableDefaultButtonStyleMixin, flexCenter } from 'constants/styles/mixins';
import { xs } from 'constants/styles/sizes';
import styled, { css } from 'styled-components';
import { Visibility } from 'types/styles';

export const FilterWrapper = styled.button`
    ${disableDefaultButtonStyleMixin};
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const ItemsAbsoluteWrapper = styled.div<Visibility>`
    position: absolute;
    width: 100%;
    top: 52px;
    left: 0;
    background-color: ${grey23};
    border-radius: 0 0 ${contentWrapperBorderRadius} ${contentWrapperBorderRadius};
    box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.24);
    overflow: auto;
    z-index: ${sideBarZIndex + 2};
    ${({ visible }) => !visible && 'visibility: hidden;'};

    @media screen and (max-width: ${xs}) {
        top: 74px;
        background-color: ${grey28};
    }
`;

export const SelectedItem = styled(ItemSpan)`
    font-weight: 700;
`;

export const RangeSelectWrapper = styled(Column)`
    @media (max-width: ${xs}) {
        ${flexCenter};
    }
`;

export const RangeValue = styled.span`
    font-size: 12px;
    font-weight: 500;
    line-height: 13px;
`;

export const StyledSection = styled(Section)`
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: 6px;

    @media (max-width: ${xs}) {
        padding-left: 10px;
        padding-right: 20px;
    }
`;

interface ButtonProps {
    buttonType?: 'primary' | 'secondary';
}

const primaryStyles = css`
    color: ${blue};

    &:hover {
        color: ${blue2};
    }
`;

const secondaryStyles = css`
    color: ${errorColor};
    margin-right: 2px;

    &:hover {
        color: #fc6d6d;
    }
`;

export const getTypeStyle = ({ buttonType }: ButtonProps) => {
    switch (buttonType) {
        case 'primary':
            return primaryStyles;
        case 'secondary':
            return secondaryStyles;
        default:
            return primaryStyles;
    }
};

export const StyledButton = styled(SimpleButton)<ButtonProps>`
    padding: 5px;
    font-size: 10px;
    ${buttonType => getTypeStyle(buttonType)};
    background-color: transparent;
`;
