import { Loader } from 'componentsNewDesign/dynamic/Loader';
import { Empty } from 'componentsNewDesign/layouts/resultLayouts/Empty';
import { Section } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { TrendingsModalWrapper } from 'componentsNewDesign/wrappers/TrendingsModalWrapper';
import { agentMemberRole, agentRole } from 'constants/defaults/users';
import { grey29 } from 'constants/styles/colors';
import { xs } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import { notFoundMessage } from 'pages/Users/constants';
import { default as React, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { agentStores } from 'stores/agent';
import { addInfluencerToAgentModal } from 'stores/initialize/initialize.modal.store';
import { usersEvents, usersStores } from 'stores/users/users';
import { FilterLayout } from './FilterLayout';
import { UserSearchCard } from './UserSearchCard';

const { closeModal } = addInfluencerToAgentModal;

export const AddInfluencerToAgentModal = () => {
    const [visible, { agentId }] = useStore(addInfluencerToAgentModal.modal);
    const { items, totalRecords } = useStore(usersStores.users);
    const memberInfoAboutAgent = useStore(agentStores.$memberInfoAboutAgent);
    const isFirst = useStore(usersStores.isFirst);
    const loading = useStore(usersStores.loading);
    const isMobile = useMediaQuery({ query: `(max-width: ${xs})` });

    const filteredItems =
        items?.filter(
            item =>
                !item.roles?.includes(agentRole) &&
                !item.roles?.includes(agentMemberRole) &&
                item.id !== memberInfoAboutAgent.agentInfo?.userId
        ) || [];

    useEffect(() => {
        !isFirst && usersEvents.setIsFirstToTrue();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <TrendingsModalWrapper expanded background={grey29} visible={visible} width="100%" onClose={() => closeModal()}>
            <FilterLayout totalRecords={totalRecords}>
                {loading ? (
                    <Section justifyCenter>
                        <Loader size="large" />
                    </Section>
                ) : (
                    <Section height="40%" justifyAround={isMobile} marginBottom="80px" overflow="scroll">
                        {filteredItems.length ? (
                            filteredItems.map(item => (
                                <UserSearchCard
                                    key={item.id}
                                    {...item}
                                    agentId={agentId}
                                    width={isMobile ? '85px' : 'fit-content'}
                                />
                            ))
                        ) : (
                            <Empty title={notFoundMessage} />
                        )}
                    </Section>
                )}
            </FilterLayout>
        </TrendingsModalWrapper>
    );
};
