import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';
import { message } from 'stores/alerts';
import { assignAgentModal } from 'stores/initialize/initialize.modal.store';

// Types

interface AgentMembersParams {
    agentId: string;
    params: Paths.AdminAgents$AgentIdMembers.Get.QueryParameters;
}

// Const

export const defaultMembersParams = {
    pageIndex: 0,
    limit: 20
};

// Events

const resetCurrentUserAgentInfo = createEvent();
const resetMemberInfoAboutAgent = createEvent();
const resetMemberAgentInfo = createEvent();
const resetAgentMembers = createEvent();
const resetAgentMembersParams = createEvent();
const setAgentMembersParams = createEvent<Partial<AgentMembersParams>>();
const setAgentMembers = createEvent<BULLZ.AgentMembersDto>();
const setCurrentUserAgentInfo = createEvent<BULLZ.AgentDtoApiResponse>();

// Effects

const addAgentRoleFx = createEffect({
    handler: async (data: BULLZ.CreateAgentRequest) => await API.agent.addAgentRole(data)
});

const addAgentRoleDoneFx = createEffect({
    handler: ({ result }: { params: BULLZ.CreateAgentRequest; result: BULLZ.AgentDtoApiResponse }) => {
        if (result.isSuccess) {
            message.success('User was successfully made an agent');
        }
    }
});

const deleteAgentRoleFx = createEffect({
    handler: async (userId: string) => await API.agent.deleteAgentRole(userId)
});

const deleteAgentRoleDoneFx = createEffect({
    handler: ({ result }: { params: string; result: BULLZ.ApiResponse }) => {
        if (result.isSuccess) {
            message.success('User stops being an agent');
        }
    }
});

const addMemberToAgentFx = createEffect({
    handler: async ({ data, agentId }: { data: BULLZ.AddMemberToAgentRequest; agentId: string }) =>
        await API.agent.addMemberToAgent(data, agentId)
});

const addMemberToAgentDoneFx = createEffect({
    handler: ({ result }: { result: BULLZ.ApiResponse }) => {
        if (result.isSuccess) {
            message.success('User added to agent successfully');
        }
    }
});

const changeMemberAgentFx = createEffect({
    handler: async ({ userId, oldAgentId, newAgentId }: { userId: string; oldAgentId: string; newAgentId: string }) => {
        try {
            const removeResult = await API.agent.removeMemberFromAgent(userId, oldAgentId);
            if (removeResult.isSuccess) {
                const agentInfoResult = await API.agent.getUserAgentInfo(newAgentId);
                if (agentInfoResult.isSuccess) {
                    const addingResult = await API.agent.addMemberToAgent(
                        {
                            userId
                        },
                        agentInfoResult.data?.id || ''
                    );
                    if (addingResult.isSuccess) {
                        message.success('User added to agent successfully');
                        assignAgentModal.closeModal();
                        return userId;
                    }
                } else {
                    throw new Error();
                }
            } else {
                throw new Error();
            }
        } catch (e) {
            console.log('Error', e);
            return '';
        }
    }
});

const removeMemberFromAgentFx = createEffect({
    handler: async ({ userId, agentId }: { userId: string; agentId: string }) =>
        await API.agent.removeMemberFromAgent(userId, agentId)
});

const removeMemberFromAgentDoneFx = createEffect({
    handler: ({ result }: { result: BULLZ.ApiResponse }) => {
        if (result.isSuccess) {
            message.success('User removed from agent successfully');
        }
    }
});

const getUserAgentInfoFx = createEffect({
    handler: async (userId: string) => await API.agent.getUserAgentInfo(userId)
});

const getPotentialAgentInfoFx = createEffect({
    handler: async ({ agentId, userId }: { agentId: string; userId: string }) => {
        const result = await API.agent.getUserAgentInfo(agentId);
        return { userId, ...result };
    }
});

const getMemberInfoAboutAgentFx = createEffect({
    handler: async (userId: string) => await API.agent.getMemberInfoAboutAgent(userId)
});

const getMemberAgentInfoFx = createEffect({
    handler: async (userId: string) => userId && (await API.agent.getUserAgentInfo(userId))
});

const getAgentMembersFx = createEffect({
    handler: async ({
        agentId,
        params
    }: {
        agentId: string;
        params: Paths.AdminAgents$AgentIdMembers.Get.QueryParameters;
    }) => await API.agent.getAgentMembers(agentId, params)
});

const updateAgentInfoFx = createEffect({
    handler: async ({ agentId, data }: { agentId: string; data: BULLZ.UpdateAgentRequest }) =>
        await API.agent.updateAgentInfo(agentId, data)
});

const updateAgentInfoDoneFx = createEffect({
    handler: ({ result }: { result: BULLZ.AgentDtoApiResponse }) => {
        if (result.isSuccess) {
            message.success('Agent commission updated');
        }
    }
});

// Stores

const $currentUserAgentInfo = createStore<BULLZ.AgentDto>({})
    .on(getUserAgentInfoFx.doneData, (_, payload) => payload.data)
    .on(setCurrentUserAgentInfo, (state, payload) => ({ ...state, ...payload.data }))
    .reset(resetCurrentUserAgentInfo);

const $memberAgentInfo = createStore<BULLZ.AgentDto>({})
    .on(getMemberAgentInfoFx.doneData, (prevState, payload) => (payload ? payload.data : prevState))
    .reset(resetMemberAgentInfo);

const $memberInfoAboutAgent = createStore<BULLZ.GetAssignedAgentResponse>({})
    .on(getMemberInfoAboutAgentFx.doneData, (_, payload) => payload.data)
    .reset(resetMemberInfoAboutAgent);

const $agentMembers = createStore<BULLZ.AgentMembersDto>({})
    .on(getAgentMembersFx.doneData, (_, payload) => payload.data)
    .on(setAgentMembers, (_, payload) => payload)
    .reset(resetAgentMembers);

const $agentMembersParams = createStore<AgentMembersParams>({
    agentId: '',
    params: defaultMembersParams
})
    .on(setAgentMembersParams, (state, payload) => ({ ...state, ...payload }))
    .reset(resetAgentMembersParams);

// Exports

export const agentEffects = {
    addAgentRoleFx,
    deleteAgentRoleFx,
    addAgentRoleDoneFx,
    deleteAgentRoleDoneFx,
    getUserAgentInfoFx,
    addMemberToAgentFx,
    addMemberToAgentDoneFx,
    getMemberInfoAboutAgentFx,
    getPotentialAgentInfoFx,
    changeMemberAgentFx,
    removeMemberFromAgentFx,
    removeMemberFromAgentDoneFx,
    getMemberAgentInfoFx,
    getAgentMembersFx,
    updateAgentInfoFx,
    updateAgentInfoDoneFx
};

export const agentStores = {
    $currentUserAgentInfo,
    $memberInfoAboutAgent,
    $memberAgentInfo,
    $agentMembers,
    $agentMembersParams
};

export const agentEvents = {
    resetCurrentUserAgentInfo,
    resetMemberInfoAboutAgent,
    resetMemberAgentInfo,
    resetAgentMembers,
    setAgentMembersParams,
    resetAgentMembersParams,
    setAgentMembers,
    setCurrentUserAgentInfo
};
