import { useMediaQuery } from '@material-ui/core';
import { CardHoverOpacityEffect } from 'componentsNewDesign/dynamic/effects';
import { UserCard } from 'componentsNewDesign/layouts/cards/TrendingUserCard';
import { cardWrapperMargin } from 'componentsNewDesign/layouts/cards/TrendingUserCard/constants';
import { CreateTrendingCardHoverModal } from 'componentsNewDesign/modals/CreateTrendingCardHoverModal';
import { AbsoluteCenterAlignment } from 'componentsNewDesign/wrappers/grid/AbsoluteWrapper';
import { TrendingCardWrapper } from 'componentsNewDesign/wrappers/TrendingCardWrapper';
import { grey23 } from 'constants/styles/colors';
import { xs } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { agentEffects, agentStores } from 'stores/agent';

export interface Props extends BULLZ.AdminGetUserCommon {
    width?: string;
    userId?: string;
}

export const UserSearchCard: FC<Props> = ({ id, isTrusted, username, profileImageUrl, width, userId }) => {
    const isMobile = useMediaQuery(`(max-width: ${xs})`);
    const memberInfoAboutAgent = useStore(agentStores.$memberInfoAboutAgent);
    const memberAgentInfo = useStore(agentStores.$memberAgentInfo);

    const selectHandleClick = () => {
        if (userId && id) {
            if (memberInfoAboutAgent.hasAgent && memberAgentInfo.id) {
                agentEffects.changeMemberAgentFx({
                    userId,
                    newAgentId: id,
                    oldAgentId: memberAgentInfo.id
                });
            } else {
                agentEffects.getPotentialAgentInfoFx({
                    userId,
                    agentId: id
                });
            }
        }
    };

    return (
        <TrendingCardWrapper
            backgroundColor={grey23}
            marginBottom={cardWrapperMargin}
            marginRight={isMobile ? '0' : cardWrapperMargin}
            width={width || 'fit-content'}
        >
            <AbsoluteCenterAlignment zIndex="5">
                <CardHoverOpacityEffect>
                    <CreateTrendingCardHoverModal onClick={selectHandleClick} />
                </CardHoverOpacityEffect>
            </AbsoluteCenterAlignment>
            <UserCard isTrusted={isTrusted} profileImageUrl={profileImageUrl} username={username} />
        </TrendingCardWrapper>
    );
};
