import { useMediaQuery } from '@material-ui/core';
import successImg from 'assets/success.svg';
import { CustomImg } from 'componentsNewDesign/common/imgComponents/CustomImg';
import { CardHoverOpacityEffect } from 'componentsNewDesign/dynamic/effects';
import { UserCard } from 'componentsNewDesign/layouts/cards/TrendingUserCard';
import { cardWrapperMargin } from 'componentsNewDesign/layouts/cards/TrendingUserCard/constants';
import { CreateTrendingCardHoverModal } from 'componentsNewDesign/modals/CreateTrendingCardHoverModal';
import { TrendingCardWrapper } from 'componentsNewDesign/wrappers/TrendingCardWrapper';
import { AbsoluteCenterAlignment } from 'componentsNewDesign/wrappers/grid/AbsoluteWrapper';
import { grey23 } from 'constants/styles/colors';
import { xs } from 'constants/styles/sizes';
import React, { FC, useState } from 'react';
import { agentEffects } from 'stores/agent';
import { BlackWrapper } from './styles';

export interface Props extends BULLZ.AdminGetUserCommon {
    width?: string;
    agentId?: string;
}

export const UserSearchCard: FC<Props> = ({ id, isTrusted, username, profileImageUrl, width, agentId }) => {
    const isMobile = useMediaQuery(`(max-width: ${xs})`);
    const [isAdded, setIsAdded] = useState(false);

    const selectHandleClick = () => {
        if (agentId && id) {
            agentEffects
                .addMemberToAgentFx({
                    data: {
                        userId: id
                    },
                    agentId
                })
                .then(result => {
                    if (result.isSuccess) {
                        setIsAdded(true);
                    }
                });
        }
    };

    return (
        <TrendingCardWrapper
            backgroundColor={grey23}
            marginBottom={cardWrapperMargin}
            marginRight={isMobile ? '0' : cardWrapperMargin}
            width={width || 'fit-content'}
        >
            <AbsoluteCenterAlignment zIndex="5">
                {isAdded ? (
                    <BlackWrapper>
                        <CustomImg height="20px" src={successImg} width="20px" />
                    </BlackWrapper>
                ) : (
                    <CardHoverOpacityEffect>
                        <CreateTrendingCardHoverModal onClick={selectHandleClick} />
                    </CardHoverOpacityEffect>
                )}
            </AbsoluteCenterAlignment>

            <UserCard isTrusted={isTrusted} profileImageUrl={profileImageUrl} username={username} />
        </TrendingCardWrapper>
    );
};
