import { Loader } from 'componentsNewDesign/dynamic/Loader';
import { Empty } from 'componentsNewDesign/layouts/resultLayouts/Empty';
import { FilterLayout } from 'componentsNewDesign/modals/filterModals/AssignAgentModal/FilterLayout';
import { UserSearchCard } from 'componentsNewDesign/modals/filterModals/AssignAgentModal/UserSearchCard';
import { Title } from 'componentsNewDesign/modals/filterModals/CreateTrendingUserFilterModal/styles';
import { Section } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { TrendingsModalWrapper } from 'componentsNewDesign/wrappers/TrendingsModalWrapper';
import { grey29 } from 'constants/styles/colors';
import { filterMargin, xs } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import { notFoundMessage } from 'pages/Users/constants';
import { default as React, FC, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { agentStores } from 'stores/agent';
import { assignAgentModal } from 'stores/initialize/initialize.modal.store';
import { usersEvents, usersStores } from 'stores/users/users';
import { Title as ITitle } from 'types/data';

const { closeModal } = assignAgentModal;

export const AssignAgentModal: FC<ITitle> = ({ title = 'Agents' }) => {
    const [visible, { userId }] = useStore(assignAgentModal.modal);
    const { items, totalRecords } = useStore(usersStores.users);
    const memberInfoAboutAgent = useStore(agentStores.$memberInfoAboutAgent);
    const isFirst = useStore(usersStores.isFirst);
    const loading = useStore(usersStores.loading);
    const isMobile = useMediaQuery({ query: `(max-width: ${xs})` });

    useEffect(() => {
        !isFirst && usersEvents.setIsFirstToTrue();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <TrendingsModalWrapper expanded background={grey29} visible={visible} width="100%" onClose={() => closeModal()}>
            <FilterLayout totalRecords={totalRecords}>
                <Section marginBottom={filterMargin}>
                    <Title>{title}</Title>
                </Section>
                {loading ? (
                    <Section justifyCenter>
                        <Loader size="large" />
                    </Section>
                ) : (
                    <Section height="40%" justifyAround={isMobile} marginBottom="80px" overflow="scroll">
                        {items?.length ? (
                            items.map(
                                item =>
                                    item.id !== memberInfoAboutAgent.agentInfo?.userId && (
                                        <UserSearchCard
                                            key={item.id}
                                            {...item}
                                            userId={userId}
                                            width={isMobile ? '85px' : 'fit-content'}
                                        />
                                    )
                            )
                        ) : (
                            <Empty title={notFoundMessage} />
                        )}
                    </Section>
                )}
            </FilterLayout>
        </TrendingsModalWrapper>
    );
};
