import {
    Range,
    RangeValue,
    RangeWrapper,
    Slider,
    StyledInput,
    Track
} from 'componentsNewDesign/common/inputs/RangeSlider/styles';
import { formatEngagementStatisticsValues } from 'componentsNewDesign/layouts/cards/VideoCard/constants';
import { Section } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import React, { useCallback, useEffect, useRef, useState } from 'react';

export interface RangeValues {
    rangeMin: number;
    rangeMax: number;
    selectedMin?: number;
    selectedMax?: number;
}

interface Props extends RangeValues {
    onChange: ({ selectedMin, selectedMax }: Pick<RangeValues, 'selectedMin' | 'selectedMax'>) => void;
}

export const RangeSlider = ({
    rangeMin,
    rangeMax,
    selectedMin = rangeMin,
    selectedMax = rangeMax,
    onChange
}: Props) => {
    const [selectedMinValue, setSelectedMinValue] = useState<number>(selectedMin);
    const [selectedMaxValue, setSelectedMaxValue] = useState<number>(selectedMax);
    const range = useRef(null);
    const formattedRangeMin = formatEngagementStatisticsValues(rangeMin);
    const formattedRangeMax = formatEngagementStatisticsValues(rangeMax);

    const getPercent = useCallback(value => Math.round(((value - rangeMin) / (rangeMax - rangeMin)) * 100), [
        rangeMin,
        rangeMax
    ]);

    const [rangeLeft, setRangeLeft] = useState('0%');
    const [rangeWidth, setRangeWidth] = useState('100%');

    useEffect(() => {
        const minPercent = getPercent(selectedMinValue);
        const maxPercent = getPercent(selectedMaxValue);
        setRangeLeft(`${minPercent}%`);
        setRangeWidth(`${maxPercent - minPercent}%`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMinValue, getPercent]);

    useEffect(() => {
        const minPercent = getPercent(selectedMinValue);
        const maxPercent = getPercent(selectedMaxValue);

        setRangeWidth(`${maxPercent - minPercent}%`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMaxValue, getPercent]);

    useEffect(() => {
        onChange({
            selectedMin: selectedMinValue,
            selectedMax: selectedMaxValue
        });
    }, [selectedMinValue, selectedMaxValue, onChange]);

    return (
        <RangeWrapper>
            <Section alignCenter justifyBetween noWrap marginBottom="15px">
                <RangeValue>{formattedRangeMin}</RangeValue>
                <RangeValue>{formattedRangeMax}</RangeValue>
            </Section>

            <StyledInput
                left="4px"
                max={rangeMax}
                min={rangeMin}
                type="range"
                value={selectedMinValue}
                zIndex={selectedMinValue > rangeMax - 100 ? '5' : '3'}
                onChange={event => {
                    const value = Math.min(Number(event.target.value), selectedMaxValue - 1);
                    setSelectedMinValue(value);
                }}
            />
            <StyledInput
                max={rangeMax}
                min={rangeMin}
                right="4px"
                type="range"
                value={selectedMaxValue}
                onChange={event => {
                    const value = Math.max(Number(event.target.value), selectedMinValue + 1);
                    setSelectedMaxValue(value);
                }}
            />

            <Slider>
                <Track />
                <Range ref={range} left={rangeLeft} width={rangeWidth} />
            </Slider>
        </RangeWrapper>
    );
};
