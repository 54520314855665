import { SuperAdministratorLayout } from 'components/layouts/RolesLayouts';
import { ContentText } from 'componentsNewDesign/common/typography/ContentText/styles';
import { Span } from 'componentsNewDesign/common/typography/Span';
import { PropertyBlock } from 'componentsNewDesign/layouts/blocks/PropertyBlock';
import { InteractionButton } from 'componentsNewDesign/layouts/blocks/PropertyBlock/styles';
import {
    propertyBlockDesktopWidth,
    propertyBlockLaptopWidth,
    subtitleFontSize
} from 'componentsNewDesign/layouts/descriptionLayouts/UserDescription/constants';
import { ChangeAgentCommissionPopover } from 'componentsNewDesign/modals/popovers/products/ChangeAgentCommissionPopover';
import { Row, Section } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { MarginWrapper } from 'componentsNewDesign/wrappers/grid/MarginWrapper';
import { blue, errorColor, grey27 } from 'constants/styles/colors';
import { lg_1 } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { agentEffects, agentEvents, agentStores } from 'stores/agent';
import { assignAgentModal } from 'stores/initialize/initialize.modal.store';

interface Props {
    isAgentRoleAssigned: boolean | undefined;
    userId: string;
}

export const AgentSection: FC<Props> = ({ isAgentRoleAssigned, userId }) => {
    const currentUserAgentInfo = useStore(agentStores.$currentUserAgentInfo);
    const memberInfoAboutAgent = useStore(agentStores.$memberInfoAboutAgent);
    const memberAgentInfo = useStore(agentStores.$memberAgentInfo);
    const isMobile = useMediaQuery({ query: '(max-width: 640px)' });
    const isLaptop = useMediaQuery({ query: `(max-width: ${lg_1})` });
    const blockWidth = isMobile ? '100%' : isLaptop ? propertyBlockLaptopWidth : propertyBlockDesktopWidth;

    useEffect(() => {
        if (isAgentRoleAssigned && userId) {
            agentEffects.getUserAgentInfoFx(userId);
        } else {
            agentEffects.getMemberInfoAboutAgentFx(userId);
        }

        return () => {
            agentEvents.resetCurrentUserAgentInfo();
            agentEvents.resetMemberInfoAboutAgent();
            agentEvents.resetMemberAgentInfo();
        };
    }, [isAgentRoleAssigned, userId]);

    const removeMemberFromAgent = () => {
        if (memberAgentInfo.id) {
            agentEffects.removeMemberFromAgentFx({
                agentId: memberAgentInfo.id,
                userId
            });
        }
    };

    const addAgentToMember = () => {
        assignAgentModal.openModal({
            userId
        });
    };

    return (
        <>
            <Section marginBottom="8px">
                <ContentText fontSize={subtitleFontSize}>Agent</ContentText>
            </Section>

            <Section>
                {isAgentRoleAssigned ? (
                    <>
                        <PropertyBlock
                            notEllipsisSubtitle
                            titleUppercase
                            backgroundColor={grey27}
                            marginRight="8px"
                            subtitle={currentUserAgentInfo?.membersCount?.toString() || '-'}
                            title="number of influencers"
                            width={blockWidth}
                        />
                        <PropertyBlock
                            notEllipsisSubtitle
                            titleUppercase
                            backgroundColor={grey27}
                            extra={
                                <SuperAdministratorLayout>
                                    <ChangeAgentCommissionPopover agentId={currentUserAgentInfo.userId}>
                                        <InteractionButton>
                                            <Span color={blue} fontSize="10px" fontWeight="500" lineHeight="12px">
                                                Edit
                                            </Span>
                                        </InteractionButton>
                                    </ChangeAgentCommissionPopover>
                                </SuperAdministratorLayout>
                            }
                            subtitle={
                                currentUserAgentInfo?.earningFee ? `${currentUserAgentInfo?.earningFee * 100}%` : '-'
                            }
                            title="AGENT COMMISSION"
                            width={blockWidth}
                        />
                    </>
                ) : (
                    <PropertyBlock
                        notEllipsisSubtitle
                        titleUppercase
                        backgroundColor={grey27}
                        extra={
                            <SuperAdministratorLayout>
                                {memberInfoAboutAgent.hasAgent ? (
                                    <Row>
                                        <MarginWrapper marginRight="10px">
                                            <InteractionButton onClick={removeMemberFromAgent}>
                                                <Span
                                                    color={errorColor}
                                                    fontSize="10px"
                                                    fontWeight="500"
                                                    lineHeight="12px"
                                                >
                                                    Remove
                                                </Span>
                                            </InteractionButton>
                                        </MarginWrapper>
                                        <InteractionButton onClick={addAgentToMember}>
                                            <Span color={blue} fontSize="10px" fontWeight="500" lineHeight="12px">
                                                Change
                                            </Span>
                                        </InteractionButton>
                                    </Row>
                                ) : (
                                    <InteractionButton onClick={addAgentToMember}>
                                        <Span color={blue} fontSize="10px" fontWeight="500" lineHeight="12px">
                                            Add
                                        </Span>
                                    </InteractionButton>
                                )}
                            </SuperAdministratorLayout>
                        }
                        subtitle={memberInfoAboutAgent?.agentInfo?.username || '-'}
                        title="AGENT"
                        width={blockWidth}
                    />
                )}
            </Section>
        </>
    );
};
