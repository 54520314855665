import { ArrowImg } from 'componentsNewDesign/common/imgComponents/ArrowImg';
import { RangeSlider, RangeValues } from 'componentsNewDesign/common/inputs/RangeSlider';
import { SelectWrapper, TitleSpan } from 'componentsNewDesign/common/inputs/Select/styles';
import { BackButton } from 'componentsNewDesign/common/inputs/SocialNetworksFilter/BackButton';
import { defaultMax, defaultMin } from 'componentsNewDesign/common/inputs/SocialNetworksFilter/constants';
import { NetworkList } from 'componentsNewDesign/common/inputs/SocialNetworksFilter/NetworkList';
import { RangeInputs } from 'componentsNewDesign/common/inputs/SocialNetworksFilter/RangeInputs';
import {
    FilterWrapper,
    ItemsAbsoluteWrapper,
    RangeSelectWrapper,
    SelectedItem,
    StyledButton,
    StyledSection
} from 'componentsNewDesign/common/inputs/SocialNetworksFilter/styles';
import { formatStringRangeValue } from 'componentsNewDesign/common/inputs/SocialNetworksFilter/utils';
import { formatEngagementStatisticsValues } from 'componentsNewDesign/layouts/cards/VideoCard/constants';
import { Row, Section } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { MarginWrapper } from 'componentsNewDesign/wrappers/grid/MarginWrapper';
import { ExternalNetwork, externalNetworkId, NetworkType } from 'constants/defaults/networks';
import { useStore } from 'effector-react';
import { useModal } from 'hooks/modal';
import React, { useEffect, useState } from 'react';
import { usersStores } from 'stores/users/users';

interface Props {
    onChange: ({
        externalNetworkId,
        minFollowersCount,
        maxFollowersCount
    }: Pick<BULLZ.QueryAllUsersRequest, 'externalNetworkId' | 'minFollowersCount' | 'maxFollowersCount'>) => void;
}

export const SocialNetworkFilter = ({ onChange }: Props) => {
    const { externalNetworkId: networkId, minFollowersCount, maxFollowersCount } = useStore(usersStores.values);
    const { visible, close, open } = useModal();
    const { visible: rangeSliderVisible, close: closeRangeSlider, open: openRangeSlider } = useModal();
    const [selectedNetwork, setSelectedNetwork] = useState<NetworkType | ''>('');
    const [selectedMinValue, setSelectedMinValue] = useState<string | undefined>();
    const [selectedMaxValue, setSelectedMaxValue] = useState<string | undefined>();
    const [selectedListItem, setSelectedListItem] = useState<NetworkType | ''>('');
    const [inputMin, setInputMin] = useState<number | undefined>();
    const [inputMax, setInputMax] = useState<number | undefined>();

    const network = selectedNetwork || 'Not selected';

    const selectedRange = selectedMaxValue ? `${selectedMinValue || 0} to ${selectedMaxValue}` : '';

    const onNetworkClick = (item: NetworkType) => {
        if (item === selectedNetwork) {
            const min = formatStringRangeValue(selectedMinValue);
            const max = formatStringRangeValue(selectedMaxValue);

            setInputMin(min);
            setInputMax(max);
        } else {
            setInputMin(undefined);
            setInputMax(undefined);
        }
        setSelectedListItem(item);

        if (item === ExternalNetwork.Instagram) {
            setSelectedNetwork(item);
            setSelectedMinValue(undefined);
            setSelectedMaxValue(undefined);

            onChange({
                externalNetworkId: externalNetworkId[ExternalNetwork.Instagram],
                minFollowersCount: undefined,
                maxFollowersCount: undefined
            });

            close();
        } else {
            openRangeSlider();
        }
    };

    const onRangeChange = ({ selectedMin, selectedMax }: Pick<RangeValues, 'selectedMin' | 'selectedMax'>) => {
        setInputMin(selectedMin);
        setInputMax(selectedMax);
    };

    const onApplyClick = () => {
        const min = formatEngagementStatisticsValues(inputMin || 0);
        const max = formatEngagementStatisticsValues(inputMax || 0);

        setSelectedMinValue(min);
        setSelectedMaxValue(max);
        setSelectedNetwork(selectedListItem);

        onChange({
            externalNetworkId: selectedListItem ? externalNetworkId[selectedListItem] : undefined,
            minFollowersCount: formatStringRangeValue(min),
            maxFollowersCount: formatStringRangeValue(max)
        });

        closeRangeSlider();
        close();
    };

    const onCancelClick = () => {
        closeRangeSlider();
        close();
    };

    const onBackArrowClick = () => {
        closeRangeSlider();
    };

    useEffect(() => {
        const min = minFollowersCount ? formatEngagementStatisticsValues(minFollowersCount) : undefined;
        const max = maxFollowersCount ? formatEngagementStatisticsValues(maxFollowersCount) : undefined;
        let network: NetworkType | '' = '';

        for (let networkName in externalNetworkId) {
            if (externalNetworkId[networkName as NetworkType] === networkId) {
                network = networkName as NetworkType;
            }
        }
        if (network && network !== ExternalNetwork.Instagram) {
            setSelectedMinValue(min);
            setSelectedMaxValue(max);
        }

        setSelectedNetwork(network);
    }, [networkId, minFollowersCount, maxFollowersCount]);

    return (
        <SelectWrapper minWidth="204px">
            <FilterWrapper onClick={visible ? close : open}>
                <TitleSpan>Filter by social accounts</TitleSpan>

                <Section alignCenter justifyBetween noWrap>
                    <Row alignCenter noWrap>
                        <MarginWrapper marginRight="10px">
                            <SelectedItem>{network}</SelectedItem>
                        </MarginWrapper>
                        <SelectedItem>{selectedRange}</SelectedItem>
                    </Row>
                    <ArrowImg rotate={visible ? 180 : 0} />
                </Section>
            </FilterWrapper>

            <ItemsAbsoluteWrapper visible={visible}>
                {!rangeSliderVisible && <NetworkList selectedListItem={selectedListItem} onClick={onNetworkClick} />}

                {rangeSliderVisible && (
                    <RangeSelectWrapper>
                        <StyledSection>
                            <BackButton title={selectedListItem} onClick={onBackArrowClick} />

                            <Row>
                                <StyledButton buttonType="secondary" onClick={onCancelClick}>
                                    Cancel
                                </StyledButton>
                                <StyledButton onClick={onApplyClick}>Apply</StyledButton>
                            </Row>
                        </StyledSection>
                        <Section alignCenter justifyCenter>
                            <RangeSlider
                                rangeMax={defaultMax}
                                rangeMin={defaultMin}
                                selectedMax={inputMax}
                                selectedMin={inputMin}
                                onChange={onRangeChange}
                            />
                        </Section>

                        <RangeInputs inputMax={inputMax || 0} inputMin={inputMin || 0} />
                    </RangeSelectWrapper>
                )}
            </ItemsAbsoluteWrapper>
        </SelectWrapper>
    );
};
