import { grey4, white } from 'constants/styles/colors';
import { ellipsisMixin } from 'constants/styles/mixins';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const UserNickName = styled(Link)`
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: ${white};
    ${ellipsisMixin};
    max-width: 100px;
`;

export const Dot = styled.div`
    border-radius: 50%;
    width: 4px;
    height: 4px;
    background-color: ${grey4};
    margin-left: 6px;
    margin-right: 6px;
`;
