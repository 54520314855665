import { ArrowRightIcon } from 'assets/icons';
import { socialNetworkList } from 'componentsNewDesign/common/inputs/SocialNetworksFilter/constants';
import {
    ItemWrapper,
    ListItem,
    StyledList
} from 'componentsNewDesign/common/inputs/SocialNetworksFilter/NetworkList/styles';
import { ExternalNetwork, NetworkType } from 'constants/defaults/networks';
import React from 'react';

interface Props {
    selectedListItem: '' | NetworkType;
    onClick: (item: NetworkType) => void;
}

export const NetworkList = ({ selectedListItem, onClick }: Props) => (
    <StyledList>
        {socialNetworkList.map(item => (
            <ItemWrapper key={item} active={item === selectedListItem} onClick={() => onClick(item)}>
                <ListItem>{item}</ListItem>

                {item !== ExternalNetwork.Instagram && <ArrowRightIcon height="10px" width="7px" />}
            </ItemWrapper>
        ))}
    </StyledList>
);
