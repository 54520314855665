import dashedBorder from 'assets/dashed_border.svg';
import { SimpleButton } from 'componentsNewDesign/common/buttons/SimpleButton';
import { ClickableWrapper } from 'componentsNewDesign/wrappers/ClicableWrapper';
import { Column, Row } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { black3, black4, grey23, grey28, grey37, grey38, grey39, white } from 'constants/styles/colors';
import { flexCenter } from 'constants/styles/mixins';
import { lg, xl } from 'constants/styles/sizes';
import styled, { css } from 'styled-components';

interface ImageContainerProps {
    withDecoration: boolean;
}

export const ImageContainer = styled.div<ImageContainerProps>`
    border-radius: 8px;
    height: 100%;
    width: 100%;
    overflow: hidden;
    ${flexCenter};

    ${({ withDecoration }) =>
        withDecoration &&
        css`
            background: url(${dashedBorder}) no-repeat center;
        `};
`;

export const PromotionCardButton = styled(SimpleButton)`
    border-radius: 4px;
    font-weight: 400;
    margin-bottom: 10px;
    padding: 8px;
    transition: background-color 0.3s, color 0.3s;

    :hover {
        background-color: ${grey39};
        color: ${white};
    }
`;

export const LocationsListWrapper = styled.div`
    position: absolute;
    max-height: 180px;
    min-width: 200px;
    border-radius: 0px 0px 8px 8px;
    background-color: ${grey23};
    border: 1px solid ${black4};
    box-shadow: 7px 10px 10px ${black3};
    overflow-y: scroll;
    z-index: 5;

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background-color: ${grey37};
    }
`;

export const ItemClickableWrapper = styled(ClickableWrapper)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    color: inherit;

    :hover {
        background-color: ${grey38};
    }
`;

export const StyledColumn = styled(Column)`
    width: 100%;

    @media (min-width: ${xl}) {
        flex-direction: row;
        flex-wrap: nowrap;
    }
`;

export const CardWrapper = styled.div`
    border-radius: 8px;
    background-color: ${grey28};
    padding: 16px;

    @media (min-width: ${xl}) {
        width: 745px;
        padding: 20px 25px;
    }
`;

export const FormWrapper = styled(Column)`
    width: 100%;

    @media (min-width: ${lg}) {
        padding: 25px;
    }
`;

export const StyledRow = styled(Row)`
    width: 100%;
    ${flexCenter};
    margin-bottom: 16px;

    @media (min-width: ${xl}) {
        width: fit-content;
        margin-left: 35px;
    }
`;

export const ImageBackground = styled.div`
    width: 150px;
    height: 170px;
    padding: 8px;
    border-radius: 8px;
    background-color: ${grey23};
`;

export const DataPickerWrapper = styled(Column)`
    width: 100%;
    height: 134px;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 25px;

    @media (min-width: 481px) {
        flex-direction: row;
        height: fit-content;
        margin-bottom: 35px;
        max-width: 400px;
    }

    @media (min-width: 321px) {
        height: 160px;
    }
`;
