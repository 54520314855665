import history from 'browserHistory';
import { withdrawalLimitLink } from 'constants/routes';
import { createEffect, createEvent, createStore, forward } from 'effector';
import { API } from 'services';
import { message } from 'stores/alerts';
import { creationLimitModal } from 'stores/initialize/initialize.modal.store';

export const defaultWithdrawalLimits = {
    pageIndex: 0,
    limit: 20,
    returnQueryCount: true
};

export const errorMessage = 'An error occurred, please try again later';

// Events

const updateLoading = createEvent();
const updateValues = createEvent<BULLZ.WithdrawalLimitQueryRequest>();

// Effects

const getWithdrawalLimitQuery = createEffect({
    handler: async (value: BULLZ.WithdrawalLimitQueryRequest) => {
        try {
            updateLoading();
            const data = await API.withdrawalLimit.getWithdrawalLimits(value);
            return data;
        } catch {
            return {};
        } finally {
            updateLoading();
        }
    }
});

const getWithdrawalLimitQueryByID = createEffect({
    handler: async (value: BULLZ.WithdrawalLimitGetRequest) => {
        try {
            updateLoading();
            const data = await API.withdrawalLimit.getSingleWithdrawalLimit(value);
            return data;
        } catch {
            return null;
        } finally {
            updateLoading();
        }
    }
});

interface LimitCreateRequest extends BULLZ.WithdrawalLimitCreateRequest {
    countryName?: string | null;
}

const createWithdrawalLimit = createEffect({
    handler: async ({ countryName, ...data }: LimitCreateRequest) => {
        try {
            await API.withdrawalLimit.createWithdrawalLimit(data);
            creationLimitModal.openModal({
                title: 'Success!',
                description: `You have successfully created a new withdrawal limit for ${countryName}`,
                okButtonText: 'Done',
                onOk: () => {
                    history.push(withdrawalLimitLink);
                }
            });
        } catch {
            creationLimitModal.openModal({
                title: 'Failed!',
                description: `Something went wrong. Please try again!`,
                okButtonText: 'Done',
                onOk: () => {
                    history.push(withdrawalLimitLink);
                }
            });
        }
    }
});

const deleteWithdrawalLimit = createEffect({
    handler: async (value: BULLZ.WithdrawalLimitDeleteRequest) => {
        try {
            await API.withdrawalLimit.deleteWithdrawalLimit(value);
            message.success('Withdrawal Limit Deleted');
        } catch {
            message.error(errorMessage);
        }
    }
});

interface LimitUpdateRequest extends BULLZ.WithdrawalLimitUpdateRequest {
    countryName?: string | null;
}

const updateWithdrawalLimit = createEffect({
    handler: async ({ countryName, ...data }: LimitUpdateRequest) => {
        try {
            await API.withdrawalLimit.updateWithdrawalLimit(data);
            creationLimitModal.openModal({
                title: 'Success!',
                description: `You have successfully updated a withdrawal limit for ${countryName}`,
                okButtonText: 'Done',
                onOk: () => {
                    history.push(withdrawalLimitLink);
                }
            });
        } catch {
            creationLimitModal.openModal({
                title: 'Failed!',
                description: `Something went wrong. Please try again!`,
                okButtonText: 'Done',
                onOk: () => {
                    history.push(withdrawalLimitLink);
                }
            });
        }
    }
});

// Stores

const loading = createStore(false).on(updateLoading, state => !state);

const values = createStore<BULLZ.WithdrawalLimitQueryRequest>(defaultWithdrawalLimits).on(
    updateValues,
    (state, newState) => ({ ...state, ...newState })
);

const withdrawalLimitsQuery = createStore<BULLZ.WithdrawalLimitQueryResponse>({})
    .on(getWithdrawalLimitQuery.doneData, (_, newState) => newState)
    .on(getWithdrawalLimitQueryByID.doneData, (_, newState) => ({
        items: newState ? [newState] : []
    }));

const withdrawalLimitItem = createStore<BULLZ.WithdrawalLimitResponse>({})
    .on(getWithdrawalLimitQueryByID.doneData, (_, newState) => newState ?? {})
    .on(updateWithdrawalLimit.doneData, (_, newState) => newState);

forward({
    from: values,
    to: getWithdrawalLimitQuery
});

export const withdrawalLimitsStores = { withdrawalLimitsQuery, loading, withdrawalLimitItem, values };

export const withdrawalLimitsEffects = {
    getWithdrawalLimitQuery,
    createWithdrawalLimit,
    deleteWithdrawalLimit,
    getWithdrawalLimitQueryByID,
    updateWithdrawalLimit
};

export const withdrawalLimitsEvents = { updateValues };
