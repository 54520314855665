import { useMediaQuery } from '@material-ui/core';
import whiteLinkIcon from 'assets/copy_icon_white.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download_icon.svg';
import noVideoPoster from 'assets/no_video_poster.svg';
import { Loader } from 'components/common/dynamic/Loader';
import { VideoContainer } from 'componentsNewDesign/common/dividers/VideoContainer';
import { hlsIsSupported } from 'componentsNewDesign/common/dividers/VideoContainer/constants';
import { CustomImg } from 'componentsNewDesign/common/imgComponents/CustomImg';
import { HashtagsInput } from 'componentsNewDesign/common/inputs/HashtagsInput';
import { ContentText } from 'componentsNewDesign/common/typography/ContentText/styles';
import { Span } from 'componentsNewDesign/common/typography/Span';
import { CommentBlock } from 'componentsNewDesign/layouts/blocks/CommentBlock';
import { PropertyBlock } from 'componentsNewDesign/layouts/blocks/PropertyBlock';
import { BlockTitle } from 'componentsNewDesign/layouts/blocks/PropertyBlock/styles';
import {
    formatEngagementStatisticsValues,
    popularityIndicatorTextHorizontalPadding,
    statusVideoSource
} from 'componentsNewDesign/layouts/cards/VideoCard/constants';
import {
    contentTextLineHeight,
    countTotalVideoCommentsQuantity,
    indicatorsArray,
    mobilePropertyPadding,
    mobilePropertyWidth,
    NameEngagementType,
    propertyBlockWidth,
    rejectionReasonText,
    statuses,
    subTitleColor,
    subTitleFontWeight,
    titlePadding,
    VideoCurationStatuses,
    videoPlayerWidth
} from 'componentsNewDesign/layouts/descriptionLayouts/VideoDescription/constants';
import {
    HashtagsWrapper,
    VideoCommentsWrapper
} from 'componentsNewDesign/layouts/descriptionLayouts/VideoDescription/styles';
import { Pagination } from 'componentsNewDesign/layouts/Pagination';
import { PosterLayout } from 'componentsNewDesign/layouts/PosterLayout';
import { ClickableWrapper } from 'componentsNewDesign/wrappers/ClicableWrapper';
import { ContentWrapper } from 'componentsNewDesign/wrappers/ContentWrapper';
import { DescriptionWrapper } from 'componentsNewDesign/wrappers/DescriptionWrapper';
import { Column, Row, Section } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { MarginWrapper } from 'componentsNewDesign/wrappers/grid/MarginWrapper';
import { RelativeWrapper } from 'componentsNewDesign/wrappers/grid/RelativeWrapper';
import { defaultVideoCommentsValues } from 'constants/defaults/comments';
import { homeLink, topicsLink, usersLink } from 'constants/routes';
import { black, grey27, grey29 } from 'constants/styles/colors';
import { cardMargin, descriptionPadding, filterMargin, xs } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import { saveAs } from 'file-saver';
import React, { useEffect } from 'react';
import {
    updateCommentPaginationState,
    videoCommentsEffects,
    videoCommentsEvents,
    videoCommentsStores
} from 'stores/comments/videoComments';
import { loadingStores } from 'stores/loading';
import { modalEvents } from 'stores/modals/asyncModal';
import { videosEffects, videosStores } from 'stores/videos/videos';
import { Title } from 'types/data';
import { Sizes } from 'types/styles';
import { formatDateISOString, getLanguagesName } from 'utils/usefulFunctions';

const { updateCommentValues } = videoCommentsEvents;
const { limit: defaultLimit, pageIndex: defaultPageIndex } = defaultVideoCommentsValues;

interface VideoEngagementBlockProps
    extends Pick<BULLZ.AdminGetVideoResponse, 'engagementStatistics'>,
        Pick<Sizes, 'width'>,
        Pick<Title, 'title'> {}

export const VideoEngagementBlock = ({
    engagementStatistics,
    width = '100%',
    title = 'Engagements'
}: VideoEngagementBlockProps) => (
    <Column width={width}>
        <Row alignCenter marginBottom={descriptionPadding} width={width}>
            <ContentText
                color={subTitleColor}
                fontWeight={subTitleFontWeight}
                lineHeight={contentTextLineHeight}
                padding={titlePadding}
            >
                {title}
            </ContentText>
        </Row>
        <Row alignCenter justifyBetween height="12px" marginBottom="20px" marginLeft="5px" width={width}>
            {indicatorsArray.map(({ name, imageHeight, imageSource, imageWidth }) => (
                <Row key={name} alignCenter>
                    <CustomImg alt={name} height={imageHeight} src={imageSource} width={imageWidth} />
                    <ContentText padding={`0px ${popularityIndicatorTextHorizontalPadding}`}>
                        {formatEngagementStatisticsValues(engagementStatistics?.[name as NameEngagementType] || 0)}
                    </ContentText>
                </Row>
            ))}
        </Row>
    </Column>
);

export interface VideoDescriptionProps extends BULLZ.AdminGetVideoResponse, BULLZ.BullzValidationInfo {
    link?: string;
}

const { openStatusModal } = modalEvents;

export const VideoDescription = ({
    engagementStatistics,
    id = '',
    ownerId,
    primaryProductId,
    streaming,
    username,
    audioLanguages = [''],
    utcUploaded = '',
    validation,
    isTrusted,
    commentsCount,
    thumbnailUrl,
    hashTags = [],
    isDeleted = false,
    isPublic,
    link,
    issuerInfo
}: VideoDescriptionProps) => {
    const loading = useStore(loadingStores.loading);
    const commentsLoading = useStore(videoCommentsEffects.loadVideoComments.pending);
    const videoLoading = useStore(videosStores.editLoading);
    const videoSource = useStore(videosStores.videoSource);
    const hasVideoSourceError = useStore(videosStores.hasVideoSourceError);
    const isMobile = useMediaQuery(`(max-width: ${xs})`);
    const videoComments = useStore(videoCommentsStores.videoComments);
    const totalVideoCommentsQuantity = countTotalVideoCommentsQuantity(videoComments);
    const curationEndedReason = validation?.bullz?.curationEndedReason || 0;
    const { pageIndex, limit } = useStore(videoCommentsStores.commentValues);
    const screenGrabUrl = streaming?.details?.screenGrabUrl || '';
    const videoSrc = streaming?.details?.hlsUrl;
    const curationState = validation?.bullz?.curationState;
    const languagesOfTheVideo = getLanguagesName(audioLanguages || []);
    const videoSourceData = issuerInfo && issuerInfo.source;

    const onConfirm = async (tags: string[]) => videosEffects.updateVideoTags({ id, tags });

    const onDownloadClick = () => {
        const { status, url } = videoSource;
        videosEffects.getVideoSourceFile({ videoId: id });

        if (hasVideoSourceError) {
            openStatusModal({
                status: 'error',
                title: 'Something went wrong',
                content: 'Unfortunately the video is not ready to download',
                buttonText: 'Try again',
                onClick: () => {
                    onDownloadClick();
                }
            });
        } else {
            if (status === 3 && url !== null) {
                openStatusModal({
                    status: 'success',
                    title: 'Wait is over',
                    content: 'Video is ready to be downloaded',
                    buttonText: 'Download',
                    onClick: () => {
                        saveAs(`${url}`, `${id}`);
                    }
                });
            } else {
                openStatusModal({
                    status: 'inProcess',
                    title: 'Wait please...',
                    content: 'We are generating the video. It may take 2 to 5 minutes. Then refresh a page.'
                });
            }
        }
    };

    const onCurrentPageChange = (page: number, pageSize: number | undefined = defaultLimit) => {
        updateCommentValues({
            videoId: id,
            pageIndex: page,
            limit: pageSize
        });

        updateCommentPaginationState();
    };

    useEffect(() => {
        updateCommentValues({
            videoId: id,
            pageIndex: defaultPageIndex,
            limit: defaultLimit
        });
    }, [id]);

    useEffect(() => {
        if (curationState === VideoCurationStatuses.Accepted) {
            videosEffects.getVideoSourceFile({ videoId: id });
        }
    }, [id, curationState]);

    return (
        <>
            <DescriptionWrapper backgroundColor={grey29} disabled={isDeleted} marginBottom={filterMargin}>
                <Row justifyBetween marginBottom={descriptionPadding} width={isMobile ? '100%' : videoPlayerWidth}>
                    <ContentText
                        uppercase
                        color={
                            (isDeleted && statuses[4].color) ||
                            (curationState !== undefined && statuses[curationState].color) ||
                            black
                        }
                        fontSize="14px"
                    >
                        {(isDeleted && statuses[4].text) ||
                            (curationState === 3 &&
                                `${statuses[3].text} (${rejectionReasonText[curationEndedReason]})`) ||
                            (curationState !== undefined && statuses[curationState].text)}
                    </ContentText>
                    {isPublic && (
                        <Row alignCenter>
                            <ClickableWrapper onClick={onDownloadClick}>
                                <DownloadIcon />
                            </ClickableWrapper>
                            {videoSource && videoSource.status === 3 && (
                                <MarginWrapper marginLeft="7px">
                                    <ClickableWrapper onClick={onDownloadClick}>
                                        <Span fontWeight="500">Download Ready</Span>
                                    </ClickableWrapper>
                                </MarginWrapper>
                            )}
                        </Row>
                    )}
                </Row>
                <Section noWrap={!isMobile}>
                    <Column
                        alignCenter={isMobile}
                        marginBottom={isMobile ? '16px' : '0'}
                        marginRight={isMobile ? '0' : cardMargin}
                        width={isMobile ? '100%' : 'fit-content'}
                    >
                        <RelativeWrapper height="100%" width={isMobile ? '200px' : videoPlayerWidth}>
                            <VideoContainer
                                height="250px"
                                minWidth="100px"
                                screenGrabUrl={screenGrabUrl}
                                thumbnailUrl={thumbnailUrl}
                                videoSrc={videoSrc || ''}
                                width="100%"
                            />
                        </RelativeWrapper>
                    </Column>
                    <Column width="100%">
                        <Section alignCenter justifyBetween noWrap marginBottom="20px">
                            <Section>
                                <VideoEngagementBlock
                                    engagementStatistics={engagementStatistics}
                                    title="Engagements BULLZ"
                                    width={isMobile ? '100%' : '300px'}
                                />
                            </Section>
                            {videoSourceData && (
                                <Row alignCenter noWrap marginLeft={isMobile ? '10px' : '0'}>
                                    <BlockTitle uppercase>{statusVideoSource[videoSourceData]}</BlockTitle>
                                </Row>
                            )}
                        </Section>

                        {!isMobile && (
                            <>
                                <Section noWrap>
                                    <PropertyBlock
                                        copiable
                                        backgroundColor={grey27}
                                        customCopyIcon={whiteLinkIcon}
                                        linkRoute={homeLink}
                                        marginBottom={descriptionPadding}
                                        marginRight={descriptionPadding}
                                        subtitle={id}
                                        success="Video ID was copied"
                                        title="Video ID"
                                        width={propertyBlockWidth}
                                    />

                                    <PropertyBlock
                                        copiable
                                        backgroundColor={grey27}
                                        customCopyIcon={whiteLinkIcon}
                                        linkRoute={usersLink}
                                        marginBottom={descriptionPadding}
                                        marginRight={descriptionPadding}
                                        subtitle={ownerId}
                                        success="User ID was copied"
                                        title="User ID"
                                        width={propertyBlockWidth}
                                    />

                                    <PropertyBlock
                                        copiable
                                        backgroundColor={grey27}
                                        customCopyIcon={whiteLinkIcon}
                                        linkRoute={topicsLink}
                                        marginBottom={descriptionPadding}
                                        marginRight={descriptionPadding}
                                        subtitle={primaryProductId}
                                        success="Topic ID was copied"
                                        title="Topic ID"
                                        width={propertyBlockWidth}
                                    />

                                    <PropertyBlock
                                        copiable
                                        isLink
                                        backgroundColor={grey27}
                                        customCopyIcon={whiteLinkIcon}
                                        marginBottom={descriptionPadding}
                                        subtitle={link || ''}
                                        success="Topic link was copied"
                                        title="Topic Link"
                                        width={propertyBlockWidth}
                                    />
                                </Section>
                                <Section noWrap>
                                    <PropertyBlock
                                        isDate
                                        backgroundColor={grey27}
                                        marginBottom={descriptionPadding}
                                        marginRight={descriptionPadding}
                                        subtitle={utcUploaded as string}
                                        title="Date of Creation"
                                        width={propertyBlockWidth}
                                    />

                                    <PropertyBlock
                                        backgroundColor={grey27}
                                        marginBottom={descriptionPadding}
                                        marginRight={descriptionPadding}
                                        subtitle={languagesOfTheVideo}
                                        title="Audio Language"
                                        width={propertyBlockWidth}
                                    />

                                    <PropertyBlock
                                        copiable
                                        backgroundColor={grey27}
                                        customCopyIcon={whiteLinkIcon}
                                        isTrusted={isTrusted}
                                        marginBottom={descriptionPadding}
                                        subtitle={username || ''}
                                        success="Username was copied"
                                        title="Username"
                                        width={propertyBlockWidth}
                                    />
                                </Section>
                            </>
                        )}

                        {isMobile && (
                            <>
                                <Section justifyBetween>
                                    <PropertyBlock
                                        copiable
                                        backgroundColor={grey27}
                                        linkRoute={homeLink}
                                        marginBottom={mobilePropertyPadding}
                                        subtitle={id}
                                        success="Video ID was copied"
                                        title="Copy video ID "
                                        width={mobilePropertyWidth}
                                    />

                                    <PropertyBlock
                                        copiable
                                        backgroundColor={grey27}
                                        linkRoute={usersLink}
                                        marginBottom={mobilePropertyPadding}
                                        subtitle={ownerId}
                                        success="User ID was copied"
                                        title="Copy user ID"
                                        width={mobilePropertyWidth}
                                    />

                                    <PropertyBlock
                                        copiable
                                        backgroundColor={grey27}
                                        linkRoute={topicsLink}
                                        marginBottom={mobilePropertyPadding}
                                        subtitle={primaryProductId}
                                        success="Topic ID was copied"
                                        title="Copy topic ID"
                                        width={mobilePropertyWidth}
                                    />

                                    <PropertyBlock
                                        isDate
                                        backgroundColor={grey27}
                                        marginBottom={mobilePropertyPadding}
                                        subtitle={formatDateISOString(utcUploaded)}
                                        title="Date of Creation"
                                        width={mobilePropertyWidth}
                                    />

                                    <PropertyBlock
                                        backgroundColor={grey27}
                                        marginBottom={mobilePropertyPadding}
                                        subtitle={languagesOfTheVideo}
                                        title="Audio Language"
                                        width={mobilePropertyWidth}
                                    />

                                    <PropertyBlock
                                        copiable
                                        backgroundColor={grey27}
                                        isTrusted={isTrusted}
                                        marginBottom={mobilePropertyPadding}
                                        subtitle={username || ''}
                                        success="Username was copied"
                                        title="Username"
                                        width={mobilePropertyWidth}
                                    />
                                </Section>
                            </>
                        )}

                        <HashtagsWrapper>
                            <Column
                                marginBottom={isMobile ? '16px' : '0'}
                                marginRight={isMobile ? '0' : descriptionPadding}
                                width={isMobile ? '100%' : '370px'}
                            >
                                <HashtagsInput
                                    hashTags={hashTags || undefined}
                                    loading={videoLoading || loading}
                                    type="video"
                                    width={isMobile ? '100%' : 'auto'}
                                    onConfirm={onConfirm}
                                />
                            </Column>
                            <Column>
                                <Row marginBottom={descriptionPadding}>
                                    <ContentText
                                        color={subTitleColor}
                                        fontWeight={subTitleFontWeight}
                                        lineHeight={contentTextLineHeight}
                                    >
                                        Poster
                                    </ContentText>
                                </Row>

                                <ContentWrapper
                                    backgroundColor={grey27}
                                    height="116px"
                                    minWidth="90px"
                                    padding="8px 10px"
                                    width="90px"
                                >
                                    <PosterLayout
                                        defaultPoster={noVideoPoster}
                                        height="100px"
                                        posterHeight="100px"
                                        src={hlsIsSupported ? thumbnailUrl || screenGrabUrl : undefined}
                                        width="70px"
                                    />
                                </ContentWrapper>
                            </Column>
                        </HashtagsWrapper>
                    </Column>
                </Section>
            </DescriptionWrapper>
            {isPublic && (
                <VideoCommentsWrapper backgroundColor={grey29}>
                    {commentsLoading ? (
                        <Section justifyCenter>
                            <Loader size="large" />
                        </Section>
                    ) : (
                        <>
                            <Section>
                                <ContentText fontSize="14px" fontWeight="500" padding="21px 0 12px">
                                    {`${totalVideoCommentsQuantity} ${commentsCount !== undefined && ` Comments`}`}
                                </ContentText>
                            </Section>
                            {videoComments?.items?.map((comment, index) => (
                                <CommentBlock
                                    key={comment.body + `${index}`}
                                    threadComments={comment.replies || undefined}
                                    {...comment}
                                />
                            ))}

                            <Section marginTop="50px">
                                <Pagination
                                    currentIndex={pageIndex + 1}
                                    defaultSize={limit}
                                    pagesLimit={100}
                                    totalItems={videoComments?.totalRecords}
                                    onSizeChange={onCurrentPageChange}
                                />
                            </Section>
                        </>
                    )}
                </VideoCommentsWrapper>
            )}
        </>
    );
};
