import linkIcon from 'assets/link_icon.svg';
import { ButtonImg } from 'componentsNewDesign/common/buttons/CopyButton/styles';
import { defaultMongoDBId } from 'constants/defaults/formats';
import React from 'react';

export interface LinkButtonProps {
    id?: string;
    linkRoute?: string;
    externalLink?: string;
}

export const LinkButton = ({ id, linkRoute, externalLink }: LinkButtonProps) => {
    const disabled = id === defaultMongoDBId || !id;
    const internalLink = `${linkRoute}/${id}`;

    const openLinkInANewTab = () => {
        if (!disabled) {
            window.open(externalLink || internalLink);
        }
    };

    return <ButtonImg pointer disabled={disabled} src={linkIcon} onClick={openLinkInANewTab} />;
};
