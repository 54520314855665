import { black, grey40, white } from 'constants/styles/colors';
import styled, { css } from 'styled-components';
import { AbsoluteLocation, Sizes, ZIndex } from 'types/styles';

const trackStylesMixin = css`
    height: 4px;
    border-radius: 3px;
`;

const trackWidth = css`
    width: 184px;
`;

const sliderThumbStylesMixin = css`
    background: ${black};
    border: 2px solid ${white};
    border-radius: 50%;
    cursor: pointer;
    height: 12px;
    width: 12px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
`;

export const RangeWrapper = styled.div`
    position: relative;
    width: fit-content;
    padding: 6px;
`;

export const Slider = styled.div`
    position: relative;
    ${trackWidth};
`;

export const Track = styled.div`
    position: absolute;
    width: 100%;
    ${trackStylesMixin};
    background-color: ${grey40};
    z-index: 1;
`;

interface RangeProps extends Pick<AbsoluteLocation, 'left'>, Pick<Sizes, 'width'> {}

export const Range = styled.div<RangeProps>`
    position: absolute;
    left: ${({ left }) => left || '0%'};
    width: ${({ width }) => width || '100%'};
    ${trackStylesMixin};
    background-color: ${white};
    z-index: 2;
`;

interface InputProps extends Pick<AbsoluteLocation, 'left' | 'right'>, ZIndex {}

export const StyledInput = styled.input<InputProps>`
    pointer-events: none;
    position: absolute;
    height: 0;
    ${trackWidth};
    outline: none;
    ${({ left }) => left && `left: ${left}`};
    ${({ right }) => right && `right: ${right}`};
    z-index: ${({ zIndex }) => zIndex || '4'};

    -webkit-appearance: none;

    ::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        ${sliderThumbStylesMixin};
    }

    ::-moz-range-thumb {
        ${sliderThumbStylesMixin};
    }
`;

export const RangeValue = styled.span`
    font-size: 12px;
    line-height: 13px;
    color: ${white};
`;
