import axios from './axios';

export const addAgentRole = (data: BULLZ.CreateAgentRequest) =>
    axios<BULLZ.AgentDtoApiResponse>({
        url: '/admin/agents',
        data
    });

export const deleteAgentRole = (userId: string) =>
    axios<BULLZ.ApiResponse>({
        url: `/admin/agents/${userId}`,
        method: 'DELETE'
    });

export const getUserAgentInfo = (userId: string) =>
    axios<BULLZ.AgentDtoApiResponse>({
        url: `/admin/agents/${userId}`,
        method: 'GET'
    });

export const addMemberToAgent = (data: BULLZ.AddMemberToAgentRequest, agentId: string) =>
    axios<BULLZ.ApiResponse>({
        url: `/admin/agents/${agentId}/members`,
        data
    });

export const removeMemberFromAgent = (userId: string, agentId: string) =>
    axios<BULLZ.ApiResponse>({
        url: `/admin/agents/${agentId}/members/${userId}`,
        method: 'DELETE'
    });

export const getMemberInfoAboutAgent = (userId: string) =>
    axios<BULLZ.GetAssignedAgentResponseApiResponse>({
        url: `/admin/agents/members/${userId}/assigned-agent`,
        method: 'GET'
    });

export const getAgentMembers = (agentId: string, params: Paths.AdminAgents$AgentIdMembers.Get.QueryParameters) =>
    axios<BULLZ.AgentMembersDtoApiResponse>({
        url: `/admin/agents/${agentId}/members`,
        method: 'GET',
        params
    });

export const updateAgentInfo = (agentId: string, data: BULLZ.UpdateAgentRequest) =>
    axios<BULLZ.AgentDtoApiResponse>({
        url: `/admin/agents/${agentId}`,
        method: 'PUT',
        data
    });
