import { useMediaQuery } from '@material-ui/core';
import { ModalButton } from 'componentsNewDesign/common/buttons/ModalButton';
import { Span } from 'componentsNewDesign/common/typography/Span';
import { Column, Row } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { ModalWrapper } from 'componentsNewDesign/wrappers/ModalWrapper';
import { errorColor, grey27 } from 'constants/styles/colors';
import { sm } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import React from 'react';
import { confirmPromotionActivationModal } from 'stores/initialize/initialize.modal.store';
import { getModalContent } from './constants';

export const ConfirmPromotionActivationModal = () => {
    const [visible, { /*promotionName,*/ promotionId, onOk }] = useStore(confirmPromotionActivationModal.modal);
    const modalContent = promotionId && getModalContent(promotionId);
    const isMobile = useMediaQuery(`(max-width: ${sm})`);

    const onClose = () => confirmPromotionActivationModal.closeModal();

    const okApplyClick = async () => {
        onOk();
        onClose();
    };

    return (
        <ModalWrapper height="fit-content" visible={visible} width={isMobile ? '256px' : '580px'} onClose={onClose}>
            <Column alignCenter width="100%">
                <Row marginBottom="38px" marginTop="30px" width={isMobile ? '200px' : '400px'}>
                    <Span alignCenter font-size="14px" fontWeight="500" lineHeight="18px">
                        {modalContent}
                    </Span>
                </Row>

                <Row>
                    <Column marginRight={isMobile ? '6px' : '16px'}>
                        <ModalButton background={grey27} onClick={okApplyClick}>
                            OK
                        </ModalButton>
                    </Column>

                    <ModalButton background={errorColor} onClick={onClose}>
                        Cancel
                    </ModalButton>
                </Row>
            </Column>
        </ModalWrapper>
    );
};
