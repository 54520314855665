import { Section } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { grey35 } from 'constants/styles/colors';
import { disableDefaultInputStyleMixin } from 'constants/styles/mixins';
import { xs } from 'constants/styles/sizes';
import styled from 'styled-components';

export const Input = styled.input`
    ${disableDefaultInputStyleMixin};
    font-size: 12px;
    font-weight: 500;
    line-height: 13px;
    color: white;
    text-align: left;
    padding: 7px;
    width: 60px;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid ${grey35};
    outline: none;

    &::placeholder {
        color: rgba(153, 153, 153, 0.4);
    }

    :focus::placeholder {
        color: transparent;
    }
`;

export const InputsWrapper = styled(Section)`
    padding: 16px 10px 10px;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;

    @media (max-width: ${xs}) {
        max-width: 240px;
    }
`;
