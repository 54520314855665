import { CardButton } from 'componentsNewDesign/common/buttons/CardButton';
import { Span } from 'componentsNewDesign/common/typography/Span';
import { Column, Section } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { MarginWrapper } from 'componentsNewDesign/wrappers/grid/MarginWrapper';
import { ModalWrapper } from 'componentsNewDesign/wrappers/ModalWrapper';
import { blue, errorColor, hoverGrey2, white } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import React from 'react';
import { infoModal } from 'stores/initialize/initialize.modal.store';
import { TextSection } from './styles';

const { modal, closeModal } = infoModal;

export const InfoModal = () => {
    const [visible, state] = useStore(modal);
    const { title, text, icon: Icon, buttonText, cancelText, onClick, onCloseClick } = state;

    const handleOkClick = () => {
        closeModal();
        onClick?.();
    };

    const handleCancelClick = () => {
        closeModal();
        onCloseClick?.();
    };

    const handleClose = () => {
        closeModal();
    };

    return (
        <ModalWrapper visible={visible} width="580px" onClose={handleClose}>
            <Column noWrap marginTop="24px" width="100%">
                {Icon && (
                    <Section justifyCenter marginBottom="12px">
                        <Icon />
                    </Section>
                )}

                {title && (
                    <TextSection marginBottom="12px">
                        <Span>{title}</Span>
                    </TextSection>
                )}

                {text && (
                    <TextSection marginBottom="12px">
                        <Span>{text}</Span>
                    </TextSection>
                )}

                <Section alignCenter justifyCenter marginTop="38px">
                    <MarginWrapper marginRight={cancelText ? '8px' : '0px'}>
                        <CardButton
                            backgroundHover={hoverGrey2}
                            color={blue}
                            height="45px"
                            textHover={white}
                            width="103px"
                            onClick={handleOkClick}
                        >
                            {buttonText || 'Ok'}
                        </CardButton>
                    </MarginWrapper>
                    {cancelText && (
                        <CardButton
                            background={errorColor}
                            backgroundHover={hoverGrey2}
                            color={white}
                            height="45px"
                            type="danger"
                            width="103px"
                            onClick={handleCancelClick}
                        >
                            {cancelText || 'Cancel'}
                        </CardButton>
                    )}
                </Section>
            </Column>
        </ModalWrapper>
    );
};
