import { ArrowLeftIcon } from 'assets/icons';
import { SelectedItem } from 'componentsNewDesign/common/inputs/SocialNetworksFilter/styles';
import { ClickableWrapper } from 'componentsNewDesign/wrappers/ClicableWrapper';
import { MarginWrapper } from 'componentsNewDesign/wrappers/grid/MarginWrapper';
import React from 'react';
import { ReactClick } from 'types/react';

interface Props extends ReactClick<HTMLButtonElement> {
    title: string;
}

export const BackButton = ({ title, onClick }: Props) => (
    <ClickableWrapper onClick={onClick}>
        <MarginWrapper marginLeft="6px" marginRight="8px">
            <ArrowLeftIcon />
        </MarginWrapper>

        <SelectedItem>{title}</SelectedItem>
    </ClickableWrapper>
);
