import { ClickableWrapper } from 'componentsNewDesign/wrappers/ClicableWrapper';
import { Row } from 'componentsNewDesign/wrappers/grid/FlexWrapper';
import { grey17, grey27, grey30, grey36, white } from 'constants/styles/colors';
import { xs } from 'constants/styles/sizes';
import styled from 'styled-components';
import { Sizes } from 'types/styles';

export const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    text-align: center;
`;

export const TableHead = styled.thead`
    width: 100%;
    background-color: ${grey27};
    color: ${grey17};
    height: 33px;
    text-align: center;
`;

export const TableRow = styled.tr`
    width: 100%;
    height: 60px;
    border-top: 1px solid rgba(196, 196, 196, 0.2);
    color: ${white};
    text-align: center;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    cursor: pointer;

    &:hover {
        background-color: ${grey27};
    }

    @media (max-width: ${xs}) {
        height: 34px;
    }
`;

export const TableHeader = styled.th<Sizes>`
    text-align: center;
    font-size: 10px;

    &:first-child {
        border-radius: 8px 0px 0px 0px;
    }
    &:last-child {
        border-radius: 0px 8px 0px 0px;
    }
`;
export const TableData = styled.td`
    text-align: center;
    padding-left: 25px;
    padding-right: 25px;
`;

export const CountryWrapper = styled(Row)`
    align-items: center;
    padding: 4px 10px 4px 10px;
    background: ${grey30};
    border: 1px solid ${grey36};
    border-radius: 32px;
    font-size: 11px;
    line-height: 13px;
    color: rgba(255, 255, 255, 0.7);
`;

export const DeleteWrapper = styled(Row)`
    align-items: center;
    justify-content: flex-end;
`;

export const DeleteButton = styled(ClickableWrapper)`
    width: 90px;
    height: 50px;

    &:hover {
        opacity: 0.5;
    }
`;

export const LimitWrapper = styled.div`
    display: grid;
    align-items: center;
    grid-gap: 8px;
    grid-template-columns: auto auto;
`;
