import { Section } from 'components/grid/Section';
import { Loader } from 'componentsNewDesign/dynamic/Loader';
import { MainLayout } from 'componentsNewDesign/layouts/MainLayout';
import { DeleteLimitModal } from 'componentsNewDesign/modals/DeleteLimitModal';
import { ContentWrapper } from 'componentsNewDesign/wrappers/ContentWrapper';
import { grey29 } from 'constants/styles/colors';
import { useStore } from 'effector-react';
import { Header } from 'pages/WithdrawalLimit/Header';
import { LimitTable } from 'pages/WithdrawalLimit/LimitTable';
import { Container, Wrapper } from 'pages/WithdrawalLimit/styles';
import React, { useEffect } from 'react';
import { locationEffects } from 'stores/location/location';
import { defaultWithdrawalLimits, withdrawalLimitsEffects, withdrawalLimitsStores } from 'stores/withdrawalLimit';
import { FilterLayout } from './FilterLayout';

export const WithdrawalLimit = () => {
    const loading = useStore(withdrawalLimitsStores.loading);

    useEffect(() => {
        withdrawalLimitsEffects.getWithdrawalLimitQuery({
            ...defaultWithdrawalLimits
        });
        locationEffects.loadListOfCountries();
    }, []);

    return (
        <MainLayout>
            <FilterLayout>
                <Container>
                    <Wrapper>
                        <Header />
                        <ContentWrapper backgroundColor={grey29} padding="48px 24px 8px">
                            {loading ? (
                                <Section justifyCenter>
                                    <Loader />
                                </Section>
                            ) : (
                                <LimitTable />
                            )}
                        </ContentWrapper>
                    </Wrapper>
                </Container>
            </FilterLayout>
            <DeleteLimitModal />
        </MainLayout>
    );
};
