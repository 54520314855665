import format from 'date-fns/format';

export const headerForCSVTable = [
    { label: 'Id', key: 'id' },
    { label: 'Withdrawal Limit', key: 'maxLimitInWom' },
    { label: 'Euros', key: 'euro' },
    { label: 'Locations', key: 'regionId' }
];

export const csvFilename = () => `withdrawal_limit_${format(new Date(), 'yyyy_MM_dd_HH_mm_ss')}.csv`;
